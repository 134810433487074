import DropDownCheckBox from "components/common/dropdown/DropDownCheckBox";
import React, { useState } from "react";
import { ReactComponent as Add } from "assets/icons/general/ef-general-add.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowDown } from "assets/icons/general/ef-general-arrowDown.svg";
import { ReactComponent as ArrowUp } from "assets/icons/general/ef-general-arrowUp.svg";
import GrowCameraDetail from "components/pages/2_manage/device/efg/GrowCameraDetail";
import EfgDetail from "components/pages/2_manage/device/efg/EfgDetail";
import { ReactComponent as Refresh } from "assets/icons/general/ef-general-refresh.svg";
import { format } from "date-fns";
import noData from "assets/images/report/no_report_data.png";
import SprayDetail from "components/pages/2_manage/device/efg/SprayDetail";
import { usePiggeries } from "query/piggery";
import { useGrowList } from "query/efg";
import NoDataImg from "assets/images/report/no_report_data.png";

function Efg() {
  const [deviceStatus, setDeviceStatus] = useState<(boolean | string)[]>([]);
  const [selectedPiggery, setSelectedPiggery] = useState<number[]>([]);
  const { t } = useTranslation();
  const [toggledId, setToggledId] = useState(0);
  const [toggleGrowDetail, setToggleGrowDetail] = useState(false);
  const [toggleCamDetail, setToggleCamDetail] = useState(false);
  const [toggleSprayDetail, setToggleSprayDetail] = useState(false);
  const [growId, setGrowId] = useState(0);
  const [camId, setCamId] = useState(0);
  const [sprayId, setSprayId] = useState(0);

  const { data: piggeryList } = usePiggeries();
  const { data: growList, isError } = useGrowList(selectedPiggery, deviceStatus);

  // 리셋 버튼용 함수
  const handleReset = () => {
    setDeviceStatus([]);
    setSelectedPiggery([]);
  };

  // 장치추가 버튼용 함수
  const handleAdd = () => {
    if (window.confirm(t("manage.add_device_comment"))) {
      window.open("https://intflow.ai/contact/", "_blank");
    } else return;
  };

  return (
    <div className="w-full h-full bg-ef-neutral-50">
      <EfgDetail isToggle={toggleGrowDetail} setIsToggle={setToggleGrowDetail} deviceId={growId} />
      <GrowCameraDetail isToggle={toggleCamDetail} setIsToggle={setToggleCamDetail} camId={camId} />
      <SprayDetail isToggle={toggleSprayDetail} setIsToggle={setToggleSprayDetail} deviceId={sprayId} />
      <div className="w-[948px] min-w-[948px] mx-auto h-fit flex flex-col pt-60pxr pb-200pxr">
        {/* title */}
        <div className="flex flex-row items-center">
          <span className="ef-headline-lg text-ef-neutral-900 mr-4pxr">EFG {t("manage.list")}</span>
          <span className="ef-headline-lg text-ef-neutral-400 mr-16pxr">{`(${growList ? growList.length : 0})`}</span>
          <button onClick={handleAdd} className="ef-btn-r8-sm flex flex-row flex-wrap gap-8pxr">
            <Add />
            <span>{t("manage.add_device")}</span>
          </button>
          <span className="ml-auto ef-body-sm text-ef-neutral-500">{`${t("common.last_updated")} : ${format(new Date(), "MM-dd HH:mm")}`}</span>
        </div>
        {/* filter */}
        <div className="mt-40pxr flex flex-row gap-24pxr items-center">
          <div className="flex flex-row gap-8pxr">
            <DropDownCheckBox
              title={t("manage.device_status")}
              state={deviceStatus}
              setState={setDeviceStatus}
              options={[
                { name: t("common.normal"), value: true },
                { name: t("common.abnormal"), value: false },
                { name: t("common.polluted"), value: "pollution" },
              ]}
            />
            <DropDownCheckBox
              title={t("common.piggery")}
              state={selectedPiggery}
              setState={setSelectedPiggery}
              options={
                piggeryList
                  ? piggeryList.map((o) => {
                      return { name: o.name, value: o.id };
                    })
                  : []
              }
            />
          </div>
          <button onClick={handleReset} type="button" className="ef-btn-reset flex flex-row gap-4pxr">
            <Refresh />
            {t("common.reset")}
          </button>
        </div>
        {/* divider */}
        <div className="w-full h-1pxr bg-ef-neutral-200 my-32pxr"></div>
        {/* body */}
        {(growList && growList.length === 0) || isError ? (
          <div className="mt-88pxr flex flex-col gap-8pxr w-fit mx-auto">
            <img src={noData} alt="no-device" />
            <p className="ef-body-xxl text-ef-neutral-600 mx-auto">{t("manage.there_is_no_device_registered")}</p>
          </div>
        ) : (
          <div className="w-full flex flex-col flex-wrap gap-16pxr">
            {growList?.map((o) => (
              <button
                key={o.id}
                onClick={() => {
                  if (toggledId === o.id) setToggledId(0);
                  else setToggledId(o.id);
                }}
                className={
                  "w-full flex flex-col flex-wrap gap-8pxr rounded-[8px] bg-ef-neutral-white border border-solid " +
                  (toggledId === o.id
                    ? "border-ef-primary-500 ef-main-shadow "
                    : "border-ef-neutral-150 hover:ef-main-shadow hover:border-ef-primary-500 active:bg-ef-neutral-50 active:border-ef-neutral-150 active:shadow-none focus:outline-none focus:border-ef-neutral-900")
                }
              >
                <div className="flex flex-row text-left">
                  <div className="w-176pxr flex flex-col flex-wrap gap-4pxr py-26pxr pl-24pxr pr-16pxr">
                    <span className="ef-label-sm text-ef-primary-500 line-clamp-1 w-full break-all">{t("manage.device_name")}</span>
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setGrowId(o.id);
                        setToggleGrowDetail(true);
                      }}
                      className="ef-label-lg w-full break-all line-clamp-1 underline underline-offset-4"
                    >
                      {o.name}
                    </span>
                  </div>
                  <div className="w-176pxr flex flex-col flex-wrap gap-4pxr py-26pxr pl-24pxr pr-16pxr">
                    <span className="ef-label-sm text-ef-primary-500 line-clamp-1 w-full break-all">{t("manage.installed_location")}</span>
                    <span className="ef-label-lg w-full break-all line-clamp-1">{o.piggery.name}</span>
                  </div>
                  <div className="w-176pxr flex flex-col flex-wrap gap-4pxr py-26pxr pl-24pxr pr-16pxr">
                    <span className="ef-label-sm text-ef-primary-500 line-clamp-1 w-full break-all">{t("common.device_connection_status")}</span>
                    <div className="flex flex-row items-center">
                      <div className={"w-6pxr h-6pxr rounded-full mr-4pxr " + (o.connected ? "bg-ef-state-green-500" : "bg-ef-state-red-500")}></div>
                      <span className="ef-label-lg w-full break-all line-clamp-1">{o.connected ? t("common.normal") : t("common.abnormal")}</span>
                    </div>
                  </div>
                  <div className="w-226pxr flex flex-col flex-wrap gap-4pxr py-26pxr pl-24pxr pr-16pxr">
                    <span className="ef-label-sm text-ef-primary-500 line-clamp-1 w-full break-all">{t("manage.serial_number")}</span>
                    <span className="ef-label-lg w-full break-all line-clamp-1 select-text cursor-text">{o.serial_number}</span>
                  </div>
                  <div className="w-126pxr flex flex-col flex-wrap gap-4pxr py-26pxr pl-24pxr pr-16pxr">
                    <span className="ef-label-sm text-ef-primary-500 line-clamp-1 w-full break-all">ID</span>
                    <span className="ef-label-lg w-full break-all line-clamp-1 select-text cursor-text">{o.id}</span>
                  </div>
                  <div className="my-auto mr-24pxr ml-auto stroke-ef-neutral-700">{toggledId === o.id ? <ArrowUp /> : <ArrowDown />}</div>
                </div>
                {toggledId === o.id ? (
                  <div className="px-24pxr pb-24pxr">
                    <table className="ef-table-lg w-full">
                      <thead>
                        <tr>
                          {/* <th className="w-190pxr max-w-[190px]">{t("manage.device_name")}</th> */}
                          <th className="w-305pxr max-w-[305px]">{t("manage.device_name")}</th>
                          <th className="w-153pxr max-w-[153px]">{t("manage.detection_location")}</th>
                          <th title={t("common.device_connection_status")} className="w-115pxr max-w-[115px]">
                            {t("common.device_connection_status")}
                          </th>
                          <th title={t("common.camera_contamination_status")} className="w-140pxr max-w-[140px]">
                            {t("common.camera_contamination_status")}
                          </th>
                          <th title={t('common.screen_abnormality_status')} className="w-115pxr max-w-[115px] ">
                            {t('common.screen_abnormality_status')}
                          </th>
                          {/* <th className="w-175pxr max-w-[175px]">{t("manage.serial_number")}</th> */}
                          <th className="w-72pxr max-w-[72px]">ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {o.cameras.map((c) => (
                          <tr key={c.id}>
                            <td>
                              <div className="flex flex-row items-center py-8pxr gap-16pxr">
                                <div className="rounded-[4px] w-87pxr h-56pxr relative">
                                  <img
                                    src={c.image_path}
                                    onError={(e) => (e.currentTarget.hidden = true)}
                                    alt="image_path"
                                    className="w-full h-full rounded-[4px] absolute top-0pxr left-0pxr z-[10]"
                                  />
                                  <div className="w-full h-full bg-ef-neutral-white absolute top-0pxr left-0pxr rounded-[4px] border border-solid border-ef-neutral-150 flex items-center justify-center">
                                    <img src={NoDataImg} alt="no-img" className="w-46pxr h-32pxr" />
                                  </div>
                                </div>
                                <span
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCamId(c.id);
                                    setToggleCamDetail(true);
                                  }}
                                  className="ef-label-sm underline underline-offset-2 w-171pxr text-left max-w-[171px] overflow-hidden text-ellipsis whitespace-nowrap"
                                >
                                  {c.name}
                                </span>
                              </div>
                            </td>
                            <td className="w-204pxr max-w-[204px]">{c.room.name}</td>
                            <td>
                              <div className="flex flex-row items-center justify-center flex-wrap gap-2pxr">
                                <div className={"w-6pxr h-6pxr rounded-full " + (!c.connected ? "bg-ef-state-red-500" : "bg-ef-state-green-500")}></div>
                                <span>{!c.connected ? t("common.abnormal") : t("common.normal")}</span>
                              </div>
                            </td>
                            <td>
                              <div className="flex flex-row items-center justify-center flex-wrap gap-2pxr">
                                <div className={"w-6pxr h-6pxr rounded-full " + (c.pollution ? "bg-ef-state-red-500" : "bg-ef-state-green-500")}></div>
                                <span>{c.pollution ? t("common.polluted") : t("common.normal")}</span>
                              </div>
                            </td>
                            <td>
                              <div className="flex flex-row items-center justify-center flex-wrap gap-2pxr">
                                <div className={"w-6pxr h-6pxr rounded-full " + (c.changed_angle ? "bg-ef-neutral-500" : "bg-ef-state-green-500")}></div>
                                <span>{c.changed_angle ? t("common.abnormal") : t("common.normal")}</span>
                              </div>
                            </td>
                            {/* <td className="w-175pxr max-w-[175px] select-text cursor-text"></td> */}
                            <td className="w-95pxr max-w-[95px] select-text cursor-text">{c.id}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <></>
                )}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Efg;
