import { ReactNode } from "react";
import { ReactComponent as Account } from "assets/icons/general/ef-general-account.svg";
import { ReactComponent as Farm } from "assets/icons/general/ef-general-farm.svg";
import { ReactComponent as Device } from "assets/icons/general/ef-general-device.svg";
import { ReactComponent as Person } from "assets/icons/general/ef-general-person.svg";
import { ReactComponent as Calendar } from "assets/icons/general/ef-general-calendar.svg";
import { ReactComponent as Fan } from "assets/icons/general/ef-general-fan.svg";
import { ReactComponent as Documents } from "assets/icons/general/ef-general-documents.svg";
import { ReactComponent as Clipboard } from "assets/icons/general/ef-general-clipboard.svg";
import { ReactComponent as ClipboardList } from "assets/icons/general/ef-general-clipboardList.svg";
import { ReactComponent as DataIcon } from "assets/icons/general/ef-general-data.svg";
import { ReactComponent as Stock } from "assets/icons/general/ef-general-stock.svg";
import { ReactComponent as GraphUp } from "assets/icons/general/ef-general-graphUp.svg";
import { ReactComponent as Shield } from "assets/icons/general/ef-general-shieldCheck.svg";
import { ReactComponent as User } from "assets/icons/general/ef-general-user.svg";

export interface Gnb {
  name: string;
  disabled: boolean;
  prefix: string;
  subGnb: SubGnb[];
  link: string;
}

export interface SubGnb {
  name: string;
  icon: ReactNode;
  description: string;
  child: ChildGnb[];
  link: string;
}

export interface ChildGnb {
  name: string;
  link: string;
}

export const GnbList: Gnb[] = [
  // 현황
  { name: "gnb.status", disabled: false, prefix: "", link: "/", subGnb: [] },
  // 관리
  {
    name: "gnb.manage",
    disabled: false,
    prefix: "manage",
    link: "/manage/stock/record",
    subGnb: [
      // {
      //   name: "작업관리",
      //   icon: <Calendar />,
      //   description: "gnb.worker_schedule_management_comment",
      //   link: "",
      //   child: [
      //     { name: "주간일정", link: "/manage/schedule/weekly" },
      //     // { name: "수행이력", link: "/" },
      //   ],
      // },
      {
        name: "gnb.inventory_management",
        icon: <Stock />,
        description: "gnb.inventory_management_comment",
        link: "",
        child: [
          { name: "gnb.movement_record", link: "/manage/stock/movement" },
          { name: "gnb.herd_history", link: "/manage/stock/group" },
          { name: "gnb.stock_history", link: "/manage/stock/record" },
          // { name: "출하기록", link: "/manage/" },
        ],
      },
      {
        name: "gnb.specification_management",
        icon: <GraphUp />,
        description: "gnb.specification_management_comment",
        link: "",
        child: [
          { name: "gnb.growth_record", link: "/manage/specification/growth" },
          { name: "gnb.feeding_records", link: "/manage/specification/feed" },
          // { name:"건강기록", link :""}
        ],
      },
      {
        name: "gnb.environment_management",
        icon: <Fan />,
        description: "gnb.environmental_management_comment",
        link: "",
        child: [
          { name: "gnb.environmental_records", link: "/manage/environment/sensorLog" },
          // { name: "환기제어", link: "/" },
        ],
      },
      // { name: "방역관리", icon: <Shield />, description: "", child: [] },
      // { name: "품목관리", icon: <ClipboardList />, description: "", child: [] },
      {
        name: "gnb.device_management",
        icon: <Device />,
        description: "gnb.device_management_comment",
        link: "",
        child: [
          { name: "EFC", link: "/manage/device/efcr" },
          { name: "EFG", link: "/manage/device/efg" },
          { name: "gnb.feeder", link: "/manage/device/feeder" },
          { name: "gnb.environmental_sensor", link: "/manage/device/sensor" },
        ],
      },
    ],
  },
  // 보고서
  {
    name: "gnb.report",
    disabled: false,
    prefix: "report",
    link: "/report/operation",
    subGnb: [
      { name: "gnb.operation_report", icon: <Documents />, description: "gnb.operation_report_comment", link: "/report/operation", child: [] },
      // { name: "gnb.performance_report", icon: <Clipboard />, description: "", link: "", child: [] },
    ],
  },
  // 알림
  { name: "gnb.alarm", disabled: true, prefix: "alarm", link: "/alarm", subGnb: [] },
  // 설정
  {
    name: "gnb.settings",
    disabled: false,
    prefix: "settings",
    link: "/settings/farm/piggery",
    subGnb: [
      {
        name: "gnb.farm_settings",
        icon: <Farm />,
        description: "gnb.farm_settings_comment",
        link: "",
        child: [
          { name: "gnb.basic_information_settings", link: "/settings/farm/info" },
          { name: "gnb.piggery_room_settings", link: "/settings/farm/piggery" },
        ],
      },
      {
        name: "gnb.data_settings",
        icon: <DataIcon />,
        description: "gnb.data_settings_comment",
        link: "",
        child: [
          // { name: "settings.fattening_shipment_goal_weight", link: "/settings/data/weight" },
          { name: "gnb.standard_data_settings", link: "/settings/data/standard" },
          // { name: "목표급이량설정", link: "/settings/feed" },
          // { name: "FCR설정", link: "/settings/fcr" },
          // { name: "타기관연계설정(API)", link: "/settings/apis" },
        ],
      },
      // { name: "거래처설정", icon: <Account />, description: "사료회사, 약품회사 등 농장과 거래하는 개인이나 외부 업체를 설정할 수 있어요.", child: [] },
      // { name: "작업자설정", icon: <Person />, description: "농장 작업자들의 정보를 설정할 수 있어요.", child: [] },
      { name: "gnb.account_settings", icon: <User />, description: "gnb.account_settings_comment", link: "/settings/account", child: [] },
    ],
  },
  { name: "gnb.streaming", disabled: false, prefix: "streaming", link: "/streaming", subGnb: [] },
  // { name: "efIoT", disabled: false, prefix: "efIoT", link: "http://34.64.198.216:8000", subGnb: [] },
];
