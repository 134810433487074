import React, { useState } from "react";
import { Modal } from "@mui/material";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { useTranslation } from "react-i18next";
import StreamOverlay from "../StreamOverlay";
import { MonitoringList } from "query/monitoring/type";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

interface Props {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  streamUrl: string;
  roomName: string;
  piggeryName: string;
  cameraName: string;
  hidePiggeryName?: boolean;
  roomData: MonitoringList;
  camera: MonitoringList['efg_cameras'][0];
}

function MainStream(props: Props) {
  const [isOverlayOn, setIsOverlayOn] = useState(true);
  const { t } = useTranslation();

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    props.setIsToggle(false);
  };

  return (
    <>
      <Modal
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "#303030",
            opacity: "0.3 !important",
          },
        }}
        open={props.isToggle}
        onClose={(_, reason) => onClose(reason)}
        className="flex justify-center min-w-[1070px] pt-120pxr overflow-y-auto pb-120pxr"
      >
        <div className="w-950pxr h-702pxr bg-ef-neutral-white outline-none flex flex-col p-40pxr">
          <div className="flex relative w-full">
            <span className="flex mx-auto ef-neutral-900 ef-title-lg">
              {props.hidePiggeryName ? `${props.roomName} - ${props.cameraName}` : `[${props.piggeryName}]${props.roomName} - ${props.cameraName}`} {t("status.streaming")}
            </span>
            <button className="absolute right-0pxr" onClick={() => onClose("closeButtonClick")}>
              <GeneralClose className="stroke-ef-neutral-900" />
            </button>
          </div>
          <div className="flex flex-row items-center pt-40pxr pb-24pxr">
            <div className="ef-label-lg ef-neutral-900">
              {t('common.overlay')}
            </div>
            <div className="pl-16pxr">
              <button
                type="button"
                onClick={() => setIsOverlayOn(prev => !prev)}
                className="w-120pxr h-40pxr rounded-[8px] border border-solid border-ef-neutral-150 bg-ef-neutral-white p-4pxr flex flex-row text-ef-neutral-200 relative ef-label-sm"
              >
                <div
                  className={
                    "w-55pxr h-30pxr rounded-[6px] absolute transition-transform duration-300 " + 
                    (isOverlayOn ? "bg-ef-primary-500 translate-x-full" : "bg-ef-neutral-400 translate-x-0")
                  }
                ></div>
                <div className={"w-1/2 flex items-center justify-center h-full z-[10] " + (!isOverlayOn && "text-ef-neutral-white")}>OFF</div>
                <div className={"w-1/2 flex items-center justify-center h-full z-[10] " + (isOverlayOn && "text-ef-neutral-white")}>ON</div>
              </button>
            </div>
          </div>
          <div className="w-full aspect-video relative">
            <iframe
              src={props.streamUrl.replace('/sub/', '/main/')}
              className="absolute top-0 left-0 w-full h-full object-cover"
              allowFullScreen
              allow="camera; microphone; autoplay"
            />
            <StreamOverlay 
              monitoringData={props.roomData}
              camera={props.camera}
              isOverlayOn={isOverlayOn}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default MainStream;