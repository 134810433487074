// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#data-table thead {
  background-color: #f5faff;
}

#data-table thead tr th {
  padding: 12px 16px;
  border-top: 1px solid #dedede;
}

#data-table tbody tr td {
  padding: 12px;
  border-bottom: 1px solid #dedede;
}

#data-table thead tr th:first-of-type {
  position: sticky;
  left: 0;
  background-color: #f5faff;
  border-left: 1px solid #dedede !important;
}

#data-table thead tr th:first-child {
  box-shadow: -5px -5px 0 #f8f8f8;
}

#data-table .td-header {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  border-left: 1px solid #dedede !important;
}

#data-table {
  border-right: 1px solid #dedede !important;
  box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/3_report/operation/daily/dailyReport.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,gCAAgC;AAClC;;AAEA;EAEE,gBAAgB;EAChB,OAAO;EACP,yBAAyB;EACzB,yCAAyC;AAC3C;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,WAAW;EACX,eAAe;EACf,eAAe;EACf,yCAAyC;AAC3C;;AAEA;EACE,0CAA0C;EAC1C,gBAAgB;AAClB","sourcesContent":["#data-table thead {\n  background-color: #f5faff;\n}\n\n#data-table thead tr th {\n  padding: 12px 16px;\n  border-top: 1px solid #dedede;\n}\n\n#data-table tbody tr td {\n  padding: 12px;\n  border-bottom: 1px solid #dedede;\n}\n\n#data-table thead tr th:first-of-type {\n  position: -webkit-sticky;\n  position: sticky;\n  left: 0;\n  background-color: #f5faff;\n  border-left: 1px solid #dedede !important;\n}\n\n#data-table thead tr th:first-child {\n  box-shadow: -5px -5px 0 #f8f8f8;\n}\n\n#data-table .td-header {\n  width: 80px;\n  min-width: 80px;\n  max-width: 80px;\n  border-left: 1px solid #dedede !important;\n}\n\n#data-table {\n  border-right: 1px solid #dedede !important;\n  box-shadow: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
