import NavFilters from "components/pages/1_farmStatus/NavFilters";
import { useStockDashboard } from "query/piggery";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ReactComponent as Filter } from "assets/icons/general/ef-general-filter.svg";
import { ReactComponent as Plus } from "assets/icons/general/ef-general-plus.svg";
import { useTranslation } from "react-i18next";
import Popover from "@mui/material/Popover";
import { ReactComponent as CCTV } from "assets/icons/general/ef-general-cctv.svg";
import { ReactComponent as Weigher } from "assets/icons/general/ef-general-weigher.svg";
import { ReactComponent as Pig } from "assets/icons/general/ef-general-pig.svg";
import { ReactComponent as Calendar } from "assets/icons/general/ef-general-calendar.svg";
import { ReactComponent as Siren } from "assets/icons/general/ef-general-siren.svg";
import BackHomeBtn from "components/pages/1_farmStatus/BackHomeBtn";
import { differenceInCalendarDays, format } from "date-fns";
import ComponentSpinner from "components/common/spinner/spinner";
import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import NoDataImg from "assets/images/report/no_report_data.png";
import { ReactComponent as GridSvg } from "assets/images/status/grid.svg";
import PiggeryMonitoringModal from "components/pages/1_farmStatus/modal/stock/piggeryMonitoringModal";
import RoomDetailModal from "components/pages/1_farmStatus/modal/stock/RoomDetailModal";
import ConfirmModal from "components/pages/1_farmStatus/modal/stock/ConfirmModal";
import { useAutoHerdData } from "query/herd";
import { AutoHerdSingle } from "query/herd/type";

// autoherd용 추가 타입 지정
export interface DataforConfirm extends AutoHerdSingle {
  type: "in" | "out";
  index: number;
}

// 툴팁용 기본 css
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    color: "#303030",
    boxShadow: "6px 6px 15px 0px rgba(14, 44, 74, 0.08)",
    maxWidth: "none",
    border: "1px solid #DEDEDE",
    borderRadius: "8px",
    padding: "16px 16px 24px 16px",
    fontFamily: "Pretendard",
  },
}));

function StockMap() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  // state for room div load
  const [parentWidth, setParentWidth] = useState(0);
  const [parentHeight, setParentHeight] = useState(0);
  const imageRef = useRef<HTMLDivElement | null>(null);

  // state for filter
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedFilter, setSelectedFilter] = useState(["stock", "age", "date", "dense"]);

  // state for modal
  const [selectedRoom, setSelectedRoom] = useState(0);
  const [toggleDetailModal, setToggleDetailModal] = useState(false);
  const [piggeryMonitoringModal, setPiggeryMonitoringModal] = useState(false);

  // state for confirm modal
  const [currentQueueIdx, setCurrentQueueIdx] = useState(0);
  const [toggleConfirm, setToggleConfirm] = useState(false); // data가 들어오면 on

  // query
  const { data: roomData, isLoading: dataLoading } = useStockDashboard(typeof id !== "undefined" ? Number(id) : 0);
  const { data: autoData } = useAutoHerdData();

  // autoherd Data를 하나의 배열로 제작
  const autoHerdData: DataforConfirm[] = useMemo(() => {
    if (autoData) {
      return [
        ...autoData.in.map((item, index) => ({
          ...item,
          type: "in" as "in",
          index,
        })),
        ...autoData.out.map((item, index) => ({
          ...item,
          type: "out" as "out",
          index: autoData.in.length + index, // `in`의 길이만큼 추가된 값으로 시작
        })),
      ];
    } else return [];
  }, [autoData]);

  // filter popover function
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(null);
  };

  // handle filter checkboxes
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    setSelectedFilter((prev) => (checked ? [...prev, id] : prev.filter((filter) => filter !== id)));
  };

  // handle filter reset btn
  const handleFilterReset = () => {
    setSelectedFilter(["stock", "age", "date", "dense"]);
  };

  // get divs' poisitions
  const getPoints = (overlay: { x: number; y: number }[] | null) => {
    if (!overlay || overlay.length < 2) return { x1: 0, y1: 0, width: 0, height: 0 };

    // x와 y의 최소값과 최대값 계산
    const minX = Math.min(...overlay.map((point) => point.x));
    const minY = Math.min(...overlay.map((point) => point.y));
    const maxX = Math.max(...overlay.map((point) => point.x));
    const maxY = Math.max(...overlay.map((point) => point.y));

    // 부모 요소에 맞춰 절대 위치 계산
    const x1 = minX * parentWidth;
    const y1 = minY * parentHeight;
    const x2 = maxX * parentWidth;
    const y2 = maxY * parentHeight;

    // 사각형의 너비와 높이 계산
    const width = x2 - x1;
    const height = y2 - y1;

    return { x1, y1, width, height };
  };

  // set size for room divs
  useEffect(() => {
    if (!imageRef.current) return;

    const updateSize = () => {
      setParentWidth(imageRef.current!.clientWidth);
      setParentHeight(imageRef.current!.clientHeight);
    };

    updateSize();

    const resizeObserver = new ResizeObserver(() => updateSize());
    resizeObserver.observe(imageRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  // autoherd data가 1개 이상일때만 모달 오픈
  useEffect(() => {
    if (autoHerdData.length > 0) {
      setCurrentQueueIdx(0);
      setToggleConfirm(true);
    }
  }, [autoHerdData]);

  return (
    <div className="bg-ef-primary-50 w-full h-full relative">
      <div className="absolute h-full">
        <GridSvg />
      </div>
      {autoHerdData.length > 0 && <ConfirmModal isToggle={toggleConfirm} setIsToggle={setToggleConfirm} setCurrentQueueIdx={setCurrentQueueIdx} data={autoHerdData} currentIdx={currentQueueIdx} />}
      <PiggeryMonitoringModal piggeryId={id ? Number(id) : 0} isToggle={piggeryMonitoringModal} setIsToggle={setPiggeryMonitoringModal} />
      <RoomDetailModal roomData={roomData} roomId={selectedRoom} isToggle={toggleDetailModal} setIsToggle={setToggleDetailModal} />
      <div className="w-full h-[1080px] pt-60pxr px-80pxr pb-40pxr flex flex-col relative">
        <div className="flex flex-row items-center">
          <NavFilters piggeryId={typeof id !== "undefined" ? Number(id) : 0} />
          <div className="rounded-[8px] ef-main-shadow bg-ef-neutral-white border border-solid border-ef-neutral-150 min-w-[224px] w-fit ml-auto p-12pxr flex flex-col gap-8pxr h-fit ef-body-sm text-ef-neutral-700">
            <div className="flex flex-row items-center gap-4pxr">
              <span>{t("status.stocks_in_piggery")}</span>
              <span className="text-ef-primary-500 ef-body-md">{`${roomData ? roomData.summary.today_stock.toLocaleString() : 0}${t("common.head")}`}</span>
            </div>
            <div className="w-full h-1pxr bg-ef-neutral-200"></div>
            <div>{`${t("common.last_updated")} : ${format(new Date(), "MM-dd HH:mm")}`}</div>
          </div>
          {/* <button onClick={handleClick} type="button" className={"ef-btn-map-light ml-auto " + (anchorEl ? "!text-ef-neutral-white !bg-ef-primary-500 !stroke-ef-neutral-white" : "")}>
              <Filter />
              {t("status.filter")}
            </button>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                ".MuiPaper-root": {
                  boxShadow: "6px 6px 15px 0px rgba(14, 44, 74, 0.08) !important",
                  backgroundColor: "#FFFFFF",
  
                  borderRadius: "8px",
                  border: "1px solid #DEDEDE",
                  marginTop: "4px",
                  minWidth: "270px",
                },
              }}
            >
              <div className="p-16pxr flex flex-col ef-label-md">
                <div className="flex flex-row items-center">
                  <span className="ef-title-md">{t("status.stock")}</span>
                  <button onClick={() => handleFilterReset()} type="button" className="text-ef-secondary-500 ml-auto cursor-pointer ef-label-md">
                    {t("common.reset")}
                  </button>
                </div>
                <div className="mt-8pxr mb-12pxr w-full h-1pxr bg-ef-neutral-150"></div>
                <div className="flex flex-col gap-8pxr">
                  <input type="checkbox" id="stock" checked={selectedFilter.includes("stock")} onChange={handleCheckboxChange} />
                  <label htmlFor="stock" className="!pl-32pxr h-24pxr flex items-center">
                    {t("common.number_of_stocks")}
                  </label>
                  <input type="checkbox" id="age" checked={selectedFilter.includes("age")} onChange={handleCheckboxChange} />
                  <label htmlFor="age" className="!pl-32pxr h-24pxr flex items-center">
                    {t("common.day_age")}
                  </label>
                  <input type="checkbox" id="date" checked={selectedFilter.includes("date")} onChange={handleCheckboxChange} />
                  <label htmlFor="date" className="!pl-32pxr h-24pxr flex items-center">
                    {t("common.expected_move_date")}
                  </label>
                  <input type="checkbox" id="dense" checked={selectedFilter.includes("dense")} onChange={handleCheckboxChange} />
                  <label htmlFor="dense" className="!pl-32pxr h-24pxr flex items-center">
                    {t("common.breeding_density")}
                  </label>
                </div>
              </div>
            </Popover> */}
        </div>
        <div className="min-h-[50px] mt-54pxr flex flex-row gap-24pxr items-center">
          <BackHomeBtn piggeryName={roomData ? roomData.name : ""} />
            <button onClick={() => navigate("/streaming")} type="button" className="ef-btn-regular">
              <CCTV />
              {t("status.piggery_streaming")}
            </button>
          {autoHerdData.length > 0 && (
            <div 
              onClick={() => {
                setCurrentQueueIdx(0);
                setToggleConfirm(true);
              }}
              className="ml-auto h-40pxr py-8pxr px-12pxr flex flex-row gap-8pxr items-center border border-solid border-ef-state-red-500 rounded-[8px] bg-ef-state-red-50 cursor-pointer text-ef-state-red-500
              hover:bg-ef-state-red-500 hover:text-ef-neutral-white active:bg-ef-state-red-300 active:border-transparent"
            >
              <Siren className="stroke-current" />
              <span className="ef-label-md">{t('status.move_in_out_detection')}</span>
              <span className="ef-body-xl">{autoHerdData.length}</span>
            </div>
          )}
        </div>
        <div className="w-full grow flex justify-center relative mt-55pxr">
          {dataLoading && <ComponentSpinner />}
          {/* map the rooms */}
          <div ref={imageRef} className="w-fit h-fit relative">
            <img src={roomData && roomData.image_path !== null ? roomData.image_path : ""} alt="" />
            {roomData?.rooms.map((r) => (
              <HtmlTooltip
                disableInteractive
                title={
                  <div className="flex flex-col">
                    <p className="flex flex-row justify-center">
                      <span className="ef-label-lg">{r.name}</span>
                    </p>
                    <div className="mt-8pxr mb-16pxr w-full h-1pxr bg-ef-neutral-150"></div>
                    {typeof r.summary === "undefined" ? (
                      <div className="w-377pxr flex flex-col gap-16pxr items-center pt-8pxr pb-24pxr">
                        <img src={NoDataImg} className="w-310pxr" alt="no-data" />
                        <span className="ef-body-lg text-ef-neutral-700">{t("status.its_empty_room")}</span>
                      </div>
                    ) : (
                      <>
                        <div className="flex flex-col gap-8pxr min-w-[377px] w-full bg-ef-neutral-50 rounded-[8px] px-12pxr py-16pxr">
                          <div className="flex flex-row items-center">
                            <div className="flex flex-row items-center gap-4pxr">
                              <span className="ef-body-sm text-ef-neutral-700">{t("common.number_of_stocks")}</span>
                              <span className="ef-body-md text-ef-primary-500">{`${r.summary ? r.summary.stock.toLocaleString() : "-"}${t("common.head")}`}</span>
                            </div>
                            <span className="ml-auto ef-body-xs text-ef-neutral-700">{`${t("common.stock_updated")} : ${
                              r.summary ? format(new Date(r.summary.stock_last_updated_at), "yyyy-MM-dd") : t("common.no_info")
                            }`}</span>
                          </div>
                          <div className="flex flex-row items-center gap-4pxr">
                            <span className="ef-body-sm text-ef-neutral-700">{t("common.growing_rate")}</span>
                            <span className="ef-body-md text-ef-primary-500">
                              {r.summary ? `${r.summary.growing_rate}%` : t("common.no_info")}
                              <span className="ef-body-sm text-ef-neutral-700">{r.summary && `(${t("common.death")} : ${r.summary.number_of_death}${t("common.head")})`}</span>
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-col gap-8pxr ef-body-sm mt-16pxr">
                          <div className="flex flex-row gap-8pxr items-center">
                            <span className="w-142pxr max-w-[142px] line-clamp-2 ef-title-sm break-all">{t("common.birth_date_aged_day")}</span>
                            <div className="px-8pxr border border-solid border-ef-neutral-200 w-227pxr max-w-[227px] min-h-[40px] flex items-center">
                              {r.summary ? `${r.summary.birth_date} (${r.summary.age}${t("common.aged")})` : t("common.no_info")}
                            </div>
                          </div>
                          <div className="flex flex-row gap-8pxr items-center">
                            <span className="w-142pxr max-w-[142px] line-clamp-2 ef-title-sm break-all">{`${t("common.move_in_date")}(${t("status.number_of_move_in_days")})`}</span>
                            <div className="px-8pxr border border-solid border-ef-neutral-200 w-227pxr max-w-[227px] min-h-[40px]  flex items-center">
                              {r.summary ? `${r.summary.arrival_date} (${t("manage.day", { day: r.summary.arrival_age })})` : t("common.no_info")}
                            </div>
                          </div>
                          <div className="flex flex-row gap-8pxr items-center">
                            <span className="w-142pxr max-w-[142px] line-clamp-2 ef-title-sm break-all">{`${t("common.average_weight")}(${t("status.daily_weight_gain")})`}</span>
                            <div className="px-8pxr border border-solid border-ef-neutral-200 w-227pxr max-w-[227px] min-h-[40px]  flex items-center">
                              {r.summary && r.summary.avg_weight !== null
                                ? `${r.summary.avg_weight.toFixed(2)}kg ${r.summary.weight_gain !== null ? `(${r.summary.weight_gain > 0 ? "+" : ""}${r.summary.weight_gain.toFixed(2)}kg)` : ""}`
                                : t("common.no_info")}
                            </div>
                          </div>
                          <div className="flex flex-row gap-8pxr items-center">
                            <span className="w-142pxr max-w-[142px] line-clamp-2 ef-title-sm break-all">{t("common.feeding_time")}</span>
                            <div className="px-8pxr border border-solid border-ef-neutral-200 w-227pxr max-w-[227px] min-h-[40px]  flex items-center">
                              {r.summary && r.summary.avg_feeding_time !== null ? `${t("common.average")} ${r.summary.avg_feeding_time}${t("common.minutes")}` : t("common.no_info")}
                            </div>
                          </div>
                          <div className="flex flex-row gap-8pxr items-center">
                            <span className="w-142pxr max-w-[142px] line-clamp-2 ef-title-sm break-all">{t("status.total_amount_fed_today")}</span>
                            <div className="px-8pxr border border-solid border-ef-neutral-200 w-227pxr max-w-[227px] min-h-[40px]  flex items-center">
                              {r.summary && r.summary.feed_amount_per_head !== null ? `${r.summary.feed_amount_per_head}kg` : t("common.no_info")}
                            </div>
                          </div>
                          <div className="flex flex-row gap-8pxr items-center">
                            <span className="w-142pxr max-w-[142px] line-clamp-2 ef-title-sm break-all">{t("common.vitality")}</span>
                            <div className="px-8pxr border border-solid border-ef-neutral-200 w-227pxr max-w-[227px] min-h-[40px]  flex items-center">
                              {r.summary && r.summary.avg_activity !== null ? `${t("common.average")} ${r.summary.avg_activity.toFixed(1)}m` : t("common.no_info")}
                            </div>
                          </div>
                          <div className="flex flex-row gap-8pxr items-center">
                            <span className="w-142pxr max-w-[142px] line-clamp-2 ef-title-sm break-all">{t("common.expected_move_date")}</span>
                            <div className="px-8pxr border border-solid border-ef-neutral-200 w-227pxr max-w-[227px] min-h-[40px]  flex items-center">
                              {r.summary && r.summary.due_date !== null
                                ? `${r.summary.due_date} ${
                                    r.summary.d_days !== null && r.summary.d_days > 0
                                      ? `(${t("status.day_after", { day: r.summary.d_days })}${r.summary.due_weight !== null ? ` - ${r.summary.due_weight}kg ${t("status.expectation")}` : ""})`
                                      : ""
                                  }`
                                : t("common.no_info")}
                            </div>
                          </div>
                          <div className="flex flex-row gap-8pxr items-center">
                            <span className="w-142pxr max-w-[142px] line-clamp-2 ef-title-sm break-all">{t("common.breeding_density")}</span>
                            <div className="px-8pxr border border-solid border-ef-neutral-200 w-227pxr max-w-[227px] min-h-[40px]  flex items-center">
                              {r.summary ? `${Math.floor(r.summary.breeding_density)}% (${t("status.max_accommodate", { stock: r.summary.breeding_maximum })})` : t("common.no_info")}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                }
                key={r.id}
              >
                <div
                  style={{
                    left: getPoints(r.overlay).x1,
                    top: getPoints(r.overlay).y1,
                    width: getPoints(r.overlay).width,
                    height: getPoints(r.overlay).height,
                  }}
                  className="absolute flex flex-col group cursor-pointer"
                  onClick={() => {
                    if (autoHerdData.length > 0) {
                      setCurrentQueueIdx(0);
                      setToggleConfirm(true);
                    } else {
                      setSelectedRoom(r.id);
                      setToggleDetailModal(true);
                    }
                  }}
                >
                  <div
                    className={
                      "h-32pxr max-h-32pxr px-4pxr py-2pxr flex items-center justify-center ef-label-sm text-ef-neutral-white " +
                      (typeof r.summary === "undefined" ? "bg-ef-neutral-500" : "bg-ef-primary-500 group-hover:bg-ef-secondary-500")
                    }
                  >
                    <p title={r.name} className="w-full max-w-[100%] break-all line-clamp-1 text-center">
                      {r.name}
                    </p>
                  </div>
                  {typeof r.summary === "undefined" ? (
                    <div className="flex flex-col gap-4pxr grow w-full bg-ef-neutral-500 bg-opacity-30 items-center justify-center">
                      <div className="w-40pxr h-40pxr rounded-full border border-solid border-ef-neutral-700 bg-ef-neutral-50 flex items-center justify-center">
                        <Plus className="stroke-ef-neutral-700" />
                      </div>
                      {r.vacant_period !== null && (
                        <span className="whitespace-pre-wrap ef-body-lg text-ef-neutral-700 text-center">{`${t("status.vacancy")}${getPoints(r.overlay).width > 120 ? " " : `\r\n`}${t(
                          "status.vacancy_day",
                          { day: r.vacant_period },
                        )}`}</span>
                      )}
                    </div>
                  ) : (
                    <div className="flex grow w-full  text-ef-primary-500 bg-ef-primary-500 group-hover:bg-ef-secondary-500 group-hover:bg-opacity-30  bg-opacity-30 px-4pxr flex-col gap-4pxr items-center justify-center ef-body-xs">
                      {/* 두수 */}
                      <div className="bg-ef-neutral-white flex flex-row gap-4pxr items-center rounded-[4px] w-full h-fit p-4pxr pr-8pxr min-h-[38px]">
                        <Pig className="stroke-ef-primary-500 fill-ef-primary-500 min-w-[24px]" />
                        <span className="ef-body-xxl break-all">
                          {r.summary ? r.summary.stock.toLocaleString() : "-"}
                          {getPoints(r.overlay).width > 120 ? t("common.head") : ""}
                        </span>
                      </div>
                      {/* 일령 */}
                      <div className="flex flex-row items-center gap-4pxr bg-ef-neutral-white rounded-[4px] w-full h-fit p-4pxr pr-8pxr min-h-[32px]">
                        <Calendar className="stroke-ef-primary-500 fill-ef-primary-500" />
                        <span>{r.summary ? `${r.summary.age}${t("common.aged")}` : t("common.no_info")}</span>
                      </div>
                      {/* 체중 */}
                      <div className="flex flex-row items-center gap-4pxr bg-ef-neutral-white rounded-[4px] w-full h-fit p-4pxr pr-8pxr min-h-[32px]">
                        <Weigher className="stroke-ef-primary-500 fill-ef-primary-500" />
                        <span>
                          {r.summary && r.summary.avg_weight !== null
                            ? `${r.summary.avg_weight.toFixed(2)}kg${
                                getPoints(r.overlay).width > 120 ? (r.summary.weight_gain === null ? "" : `(${r.summary.weight_gain > 0 ? "+" : ""}${r.summary.weight_gain.toFixed(2)})`) : ""
                              }`
                            : t("common.no_info")}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </HtmlTooltip>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StockMap;
