import DropDownSmall from "components/common/dropdown/DropDownSmall";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import GroupSingleDatePicker from "./GroupSingleDatePicker";
import DatePicker from "react-datepicker";
import { ReactComponent as ArrowDown } from "assets/icons/general/ef-general-arrowDown.svg";
import { format } from "date-fns";
import { useDeactivateHerd, useHerdHistoryCategory } from "query/herd";
import { toast } from "react-toastify";
import RequiredAlert from "components/common/alert/RequiredAlert";
import { RoomHistoryType } from "query/rooms/type";
import { HerdDetail } from "query/herd/type";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import { getRedStar } from "utils/getImportantStar";

type Props = {
  setModalToggle: any;
  isOpen: boolean;
  setIsOpen: any;
  herdId: number;
  historyData?: RoomHistoryType;
  groupData?: HerdDetail;
};

type Inputs = {
  category_id: number | null;
  created_at: Date;
  change?: number;
  stock?: number;
  memo: string;
};

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

const initialState = {
  category_id: null,
  created_at: new Date(),
  stock: 0,
  change: 0,
  memo: "",
};

export default function DeactivateHerdLayer(props: Props) {
  const { setModalToggle, isOpen, setIsOpen, herdId, historyData, groupData } = props;
  const { t } = useTranslation();
  const { data: categoryList } = useHerdHistoryCategory();
  const { mutateAsync: deactivateHerd } = useDeactivateHerd();
  const [selectedTime, setSelectedTime] = useState(new Date());

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    register,
    clearErrors,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: initialState });

  const category_id = watch("category_id");
  const change = watch("change");
  const stock = watch("stock");

  const handleTimePickerChange = (time: Date) => {
    if (time) {
      setSelectedTime(time);
    }
  };

  const onClose = (value: CloseReason) => {
    if (value === "backdropClick") return;
    else {
      reset();
      setIsOpen(false);
    }
  };

  const onSubmit = async (data: Inputs) => {
    const formattedData = {
      category_id: data.category_id,
      created_at: format(new Date(data.created_at), "yyyy-MM-dd") + "T" + format(selectedTime, "HH:mm:ss"),
      memo: data.memo,
      change: -Number(data.change),
      // ...(category_id !== 1 && category_id !== 2 && category_id !== 6 ? { change: -Number(data.change) } : { stock: data.stock }),
    };
    try {
      await deactivateHerd({ payload: formattedData, id: herdId });
      toast.success(t("common.herd_has_ended"));
      setIsOpen(false);
      setModalToggle(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    reset({
      created_at: new Date(format(new Date(), "yyyy-MM-dd")),
      change: historyData ? historyData?.active_herd?.summary?.stock : groupData?.stock,
      stock: historyData ? historyData?.active_herd?.summary?.stock : groupData?.stock,
    });
  }, []);

  return (
    <>
      <div className="z-10 absolute top-44pxr left-0pxr rounded-lg bg-ef-neutral-white pt-24pxr pl-24pxr pr-24pxr border border-solid ef-main-shadow">
        <span className="ef-title-md text-ef-neutral-800 flex w-full justify-center">{t("status.quit_herd_comment")}</span>
        <form onSubmit={handleSubmit(onSubmit)} className="my-40pxr flex flex-col gap-24pxr">
          <div className="w-full flex flex-row items-center">
            <span className="ef-label-md mr-44pxr">
              {t("common.work_type")}
              {getRedStar()}
            </span>
            <div className="w-168pxr relative" onClick={() => clearErrors("category_id")}>
              {errors.category_id ? <RequiredAlert /> : <></>}
              <Controller
                name="category_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <DropDownSmall
                    state={field.value}
                    setState={field.onChange}
                    placeholder={t("common.work_type")}
                    options={categoryList ? categoryList.filter((v) => v.id !== 1 && v.id !== 2 && v.id !== 6).map((o) => ({ value: o.id, name: t(`common.herd_history_${o.id}`) })) : []}
                  />
                )}
              />
            </div>
          </div>
          <div className="w-full flex flex-row items-center">
            <span className="ef-label-md mr-44pxr">
              {t("common.work_datetime")}
              {getRedStar()}
            </span>
            <div
              className="w-168pxr mr-8pxr relative"
              onClick={(e) => {
                clearErrors("created_at");
              }}
            >
              {errors.created_at ? <RequiredAlert /> : <></>}
              <Controller
                name="created_at"
                control={control}
                render={({ field }) => (
                  <GroupSingleDatePicker
                    date={field.value}
                    setDate={field.onChange}
                    minDate={
                      historyData && historyData?.active_herd?.historys?.length > 0
                        ? new Date(Math.min(...historyData?.active_herd?.historys.map((h) => new Date(h.created_at!).getTime())))
                        : groupData && groupData?.historys && groupData?.historys?.length > 0
                          ? new Date(Math.min(...groupData?.historys.map((h) => new Date(h.created_at).getTime())))
                          : undefined // 데이터가 없을 경우 현재 날짜로 설정
                    }
                    maxDate={new Date()}
                    // placeholder={groupData?.arrival_date}
                    // dateFormat={getAgedDayFormat()}
                    // maxDate={groupData ? new Date(groupData.arrival_date) : new Date()}
                    // disabled={isEditable ? false : true}
                  />
                )}
              />
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              className="flex items-center bg-ef-neutral-white w-144pxr py-8pxr pr-4pxr pl-16pxr text-left border border-solid rounded-md ef-title-md"
            >
              <DatePicker
                selected={selectedTime}
                onChange={handleTimePickerChange}
                showTimeSelect
                autoComplete="on"
                showTimeSelectOnly
                timeIntervals={1}
                placeholderText={t("common.time")}
                timeCaption={t("common.time")}
                dateFormat="h:mm"
                customInput={
                  <button className="grow flex flex-row ">
                    <span className="grow w-88pxr text-left ef-body-lg ">{format(selectedTime, "HH:mm")}</span>
                    <div className="w-24pxr mx-8pxr">
                      <ArrowDown className="stroke-ef-neutral-900 " />
                    </div>
                  </button>
                }
              />
            </div>
          </div>
          <div className="w-full flex flex-row items-center">
            <span className="mr-70pxr ef-label-md">
              {t("common.heads")}
              {getRedStar()}
            </span>
            <div className=" flex flex-row items-center gap-4pxr">
              <div
                onClick={() => clearErrors(category_id !== 1 && category_id !== 2 && category_id !== 6 ? "change" : "stock")}
                className="w-168pxr relative flex items-center"
                // className="ef-label-md group rounded-[8px] border border-solid border-ef-neutral-150 w-full h-40pxr py-8pxr px-16pxr flex flex-row focus-within:border-ef-primary-500 stroke-ef-neutral-700 bg-ef-neutral-50 text-ef-neutral-500"
              >
                {(category_id !== 1 && category_id !== 2 && category_id !== 6 ? errors.change : errors.stock) ? <RequiredAlert /> : <></>}

                <Controller
                  name={category_id !== 1 && category_id !== 2 && category_id !== 6 ? "change" : "stock"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <InputFieldOnly disabled state={field.value} setState={field.onChange} />}
                />

                {/* <input
                  type="number"
                  name={category_id !== 6 ? "change" : "stock"}
                  value={
                    category_id !== 6
                      ? groupData?.stock
                        ? String(groupData?.stock)
                        : String(historyData?.active_herd?.summary?.stock)
                      : category_id === 6
                        ? "0"
                        : String(historyData?.active_herd?.summary?.stock)
                  }
                  placeholder={
                    category_id !== 6
                      ? groupData?.stock
                        ? String(groupData?.stock)
                        : String(historyData?.active_herd?.summary?.stock)
                      : category_id === 6
                        ? "0"
                        : String(historyData?.active_herd?.summary?.stock)
                  }
                  disabled
                  className="text-right mr-8pxr w-0pxr grow focus:outline-none caret-ef-primary-900 ef-body-lg placeholder-ef-neutral-300 disabled:bg-ef-neutral-50"
                /> */}
              </div>
              두
            </div>
          </div>
          <div className="w-full flex flex-row items-center">
            <span className="mr-76pxr ef-label-md whitespace-nowrap">{t("common.memo")}</span>
            <div className="w-340pxr h-96pxr">
              <textarea {...register("memo")} className="ef-textarea w-full h-full" placeholder={t("common.enter_memo")}></textarea>
            </div>
          </div>
          <div className="w-full flex items-center justify-center gap-16pxr">
            <button
              type="button"
              className="!w-148pxr !h-48pxr ef-btn-modal-delete"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClose("closeButtonClick");
              }}
            >
              {t("common.cancel")}
            </button>
            <button type="submit" className="!w-148pxr !h-48pxr ef-btn-modal">
              {t("status.quit")}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
