import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import RequiredAlert from "components/common/alert/RequiredAlert";
import { toast } from "react-toastify";
import { isBefore, subHours } from "date-fns";
import { useEditGrowCam, useGrowCamDetail } from "query/efg";
import NoDataImg from "assets/images/report/no_report_data.png";
import { format } from "date-fns";
import AngleChangeModal from "./AngleChangeModal";
import { getRedStar } from "utils/getImportantStar";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  camId: number;
};

type Inputs = {
  name: string;
  location: string;
  network_status: string;
  pollution_status: string;
  id: number;
  angle_changed: string;
};

function GrowCameraDetail(props: Props) {
  const { isToggle, setIsToggle, camId } = props;
  const { t } = useTranslation();

  const { data: camData } = useGrowCamDetail(camId);
  const { mutateAsync: editCam } = useEditGrowCam();

  const [toggleAngleModal, setToggleAngleModal] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<Inputs>();

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    else {
      setIsToggle(false);
      reset();
    }
  };

  const onSubmit = async (data: Inputs) => {
    try {
      await editCam({ payload: { name: data.name }, id: camId });
      onClose("closeButtonClick");
      toast.success(t("common.save_completed"));
    } catch (error: any) {
      if (error.response.status === 409) toast.error(t("common.already_use_comment"), { autoClose: 2000, hideProgressBar: true });
      console.log(error);
    }
  };

  useEffect(() => {
    if (camData) {
      reset({
        name: camData.name,
        location: camData.room.name,
        network_status: camData.connected ? t("common.normal") : t("common.abnormal"),
        pollution_status: camData.pollution ? t("common.polluted") : t("common.normal"),
        id: camData.id,
        angle_changed: camData.changed_angle ? t("common.abnormal") : t("common.normal"),
      });
    }
  }, [camData]);

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center pt-120pxr overflow-y-auto pb-120pxr"
    >
      <div className="w-980pxr h-fit bg-ef-neutral-white outline-none flex flex-col p-40pxr">
        <AngleChangeModal
          isToggle={toggleAngleModal}
          setIsToggle={setToggleAngleModal}
          deviceType={"efg"}
          imgObj={{
            before_img: camData?.before_image_path,
            first_changed_img: camData?.changed_image_path,
            current_img: camData?.image_path,
            first_changed_at: camData?.changed_at,
            current_at: camData?.last_access_at,
          }}
          deviceId={camData ? camData.id : 0}
        />
        {/* header */}
        <div className="flex relative w-full">
          <span className="flex mx-auto ef-neutral-900 ef-title-lg">{`${t("manage.camera")} ${t("common.details")}`}</span>
          <button className="absolute right-0pxr" onClick={() => onClose("closeButtonClick")}>
            <GeneralClose className="stroke-ef-neutral-900" />
          </button>
        </div>
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          {/* body */}
          <div className="my-60pxr flex flex-col ef-label-md text-ef-neutral-900 flex-wrap gap-24pxr">
            <div className="flex flex-row">
              <label className="w-107pxr min-w-[107px] line-clamp-1 mr-12pxr">{t("manage.camera_image")}</label>
              <div className="flex flex-col gap-8pxr">
                <div className="w-344pxr aspect-video relative">
                  <img src={camData?.image_path} onError={(e) => (e.currentTarget.hidden = true)} alt="image_path" className="rounded-[8px] w-348pxr absolute top-0pxr left-0pxr z-[10]" />
                  <div className="w-full h-full flex-col gap-8pxr rounded-[8px] border border-solid border-ef-neutral-150 flex items-center justify-center absolute top-0pxr left-0pxr">
                    <img src={NoDataImg} alt="no-img" className="w-184pxr h-128pxr" />
                    <span className="ef-body-sm text-ef-neutral-700">{t("common.image_path_not_found")}</span>
                  </div>
                </div>
                <span className="ef-body-sm text-ef-neutral-500">{`${t("manage.loaded")} : ${format(new Date(), "yyyy-MM-dd HH:mm")}`}</span>
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-107pxr min-w-[107px] mr-12pxr flex flex-row">
                <span className="line-clamp-1 break-all">{t("manage.device_name")}</span>
                {getRedStar()}
              </label>
              <div className="w-340pxr relative" onClick={() => clearErrors("name")}>
                {errors.name ? <RequiredAlert /> : <></>}
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true, validate: { nameTrim: (value: string) => value.trim() !== "" } }}
                  render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} />}
                />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label title={t("manage.detection_location")} className="w-107pxr min-w-[107px] line-clamp-1 mr-12pxr break-all">
                {t("manage.detection_location")}
              </label>
              <div className="w-340pxr">
                <Controller name="location" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label title={t("common.device_connection_status")} className="w-107pxr min-w-[107px] line-clamp-1 mr-12pxr break-all">
                {t("common.device_connection_status")}
              </label>
              <div className="w-340pxr">
                <Controller name="network_status" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label title={t("common.camera_contamination_status")} className="w-107pxr min-w-[107px] line-clamp-1 mr-12pxr break-all">
                {t("common.camera_contamination_status")}
              </label>
              <div className="w-340pxr">
                <Controller name="pollution_status" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
              </div>
            </div>
            <div className="flex flex-row">
              <label title={t('common.screen_abnormality_status')} className="w-107pxr min-w-[107px] whitespace-nowrap text-ellipsis overflow-hidden mr-12pxr break-all mt-10pxr">
                {t('common.screen_abnormality_status')}
              </label>
              <div className="w-340pxr flex flex-col gap-8pxr">
                <Controller name="angle_changed" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
                {camData?.changed_angle === true && (
                  <span onClick={() => setToggleAngleModal(true)} className="text-ef-secondary-500 ef-label-md underline underline-offset-4 cursor-pointer">
                    {t('manage.check_screen_abnormality')}
                  </span>
                )}
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-107pxr min-w-[107px] line-clamp-1 mr-12pxr">ID</label>
              <div className="w-340pxr">
                <Controller name="id" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
              </div>
            </div>
          </div>
          <button type="submit" className="ef-btn-modal mx-auto">
            {t("common.save")}
          </button>
        </form>
      </div>
    </Modal>
  );
}

export default GrowCameraDetail;
