import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import { ReactComponent as GeneralCCTV } from "assets/icons/icon50_general/ef-50general-cctv.svg";
import { ReactComponent as GeneralWeight } from "assets/icons/icon50_general/ef-50general-weight.svg";
import { ReactComponent as GeneralPigEnergy } from "assets/icons/icon50_general/ef-50general-pigEnergy.svg";
import { ReactComponent as GeneralFork } from "assets/icons/icon50_general/ef-50general-fork.svg";
import { ReactComponent as GeneralOW } from "assets/icons/icon50_general/ef-50general-ow.svg";
import { ReactComponent as GeneralUW } from "assets/icons/icon50_general/ef-50general-uw.svg";
import { ReactComponent as GeneralReportDeath } from "assets/icons/icon50_general/ef-50general-reportDeath.svg";
import { ReactComponent as GeneralGnbMovement } from "assets/icons/icon50_general/ef-50general-gnbMovement.svg";
import { ReactComponent as GeneralRain } from "assets/icons/icon50_general/ef-50general-rain.svg";
import { ReactComponent as GeneralFlame } from "assets/icons/icon50_general/ef-50general-flame.svg";
import { ReactComponent as GeneralTemperature } from "assets/icons/icon50_general/ef-50general-temperature.svg";
import { ReactComponent as GeneralRoom } from "assets/icons/icon50_general/ef-50general-room.svg";
import { ReactComponent as GeneralPig } from "assets/icons/icon50_general/ef-50general-pig.svg";
import { ReactComponent as GeneralCalendar } from "assets/icons/icon50_general/ef-50general-calendar.svg";
import { MonitoringList } from 'query/monitoring/type';
import './overlay.css';

interface StreamOverlayProps {
  monitoringData: MonitoringList;
  camera: MonitoringList['efg_cameras'][0];
  isOverlayOn: boolean;
}

function StreamOverlay({ monitoringData, camera, isOverlayOn }: StreamOverlayProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1);
  const [width, setWidth] = useState(1929);
  const [height, setHeight] = useState(1080);

  const updateDimensions = () => {
    if (!containerRef.current) return;
    
    const container = containerRef.current.parentElement;
    if (!container) return;

    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;
    
    const containerRatio = containerWidth / containerHeight;
    const baseRatio = 1929 / 1080;
    
    let newScale;
    
    if (containerRatio > baseRatio) {
      newScale = containerHeight / 1080;
    } else {
      newScale = containerWidth / 1929;
    }
    
    setWidth(1929);
    setHeight(1080);
    setScale(newScale);
  };

  useLayoutEffect(() => {
    if (isOverlayOn) {
      updateDimensions();
    }
  }, [isOverlayOn]);

  useEffect(() => {
    const handleResize = () => {
      if (isOverlayOn) {
        updateDimensions();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isOverlayOn]);

  if (!isOverlayOn) return null;

  return (
    <div ref={containerRef} className="relative w-full h-full">
      <div 
        className="absolute top-1/2 left-1/2 origin-center"
        style={{
          width: '1929px',
          height: '1080px',
          transform: `translate(-50%, -50%) scale(${scale})`,
          transition: 'none'
        }}
      >
        {/* bg */}
        <div className="absolute inset-0 z-[1]">
          <div style={{ width: `${width}px`, height: `${height}px` }} className="flex flex-row justify-between gap-196pxr">
            <div className="w-full h-full opacity-60 bg-gradient-to-r from-[#031534] to-[rgba(2,41,104,0)]" />
            <div className="w-full h-full opacity-30 bg-gradient-to-r from-[rgba(2,41,104,0)] to-[#031534]" />
          </div>
        </div>

        {/* area */}
        <div className="absolute inset-0 z-[2]">
          <div style={{ width: `${width}px`, height: `${height}px` }}>
            {camera.food_area?.map((area, index: number) => {
              // 800x448 기준 좌표를 1920x1080 기준으로 변환
              const widthRatio = 1920 / 800;
              const heightRatio = 1080 / 448;
              
              const points = {
                x1: area.x1 * widthRatio,
                y1: area.y1 * heightRatio,
                x2: area.x2 * widthRatio,
                y2: area.y2 * heightRatio
              };

              return (
                <div
                  key={`food-area-${index}`}
                  className="absolute"
                  style={{
                    left: `${points.x1}px`,
                    top: `${points.y1}px`,
                    width: `${points.x2 - points.x1}px`,
                    height: `${points.y2 - points.y1}px`,
                    border: '8px solid rgba(255, 236, 24, 0.80)',
                    background: 'rgba(255, 236, 24, 0.40)',
                  }}
                />
              );
            })}
          </div>
        </div>

        {/* left */}
        <div className="absolute inset-0 z-[3]">
          <div style={{ width: `${width}px`, height: `${height}px` }}> 
            <div className="w-[600px] h-[728px] mt-312pxr ml-60pxr mb-40pxr">
              <div className="w-full h-full flex flex-row">
                <div className="w-[300px] flex flex-col">
                  <div>
                    <div className="h-[98px] flex flex-row items-center justify-start">
                      <GeneralCCTV className="mx-16pxr w-50pxr h-50pxr" />
                      <span className="overlay-table-title">
                        CAM (24h)
                      </span>
                    </div>
                    <div className="h-[2px] w-[600px] bg-ef-neutral-200"></div>
                  </div>
                  <div className="h-[90px] flex flex-row items-center justify-start">
                    <GeneralWeight className="mx-16pxr w-50pxr h-50pxr" />
                    <span 
                      className={`${
                        camera.avg_weight && 
                        monitoringData.avg_weight && 
                        Number(camera.avg_weight) < Number(monitoringData.avg_weight) * 0.9
                          ? 'overlay-data-red' 
                          : 'overlay-camera-data'
                      }`}
                    >
                      {(camera.avg_weight ? `${Number(camera.avg_weight).toFixed(1)}kg` : '-')}
                    </span>
                  </div>
                  <div className="h-[90px] flex flex-row items-center justify-start">
                    <GeneralPigEnergy className="mx-16pxr w-50pxr h-50pxr" />
                    <span className={`${
                      camera.avg_activity && 
                      monitoringData.avg_activity && 
                      Number(camera.avg_activity) > Number(monitoringData.avg_activity) * 1.1
                        ? 'overlay-data-red' 
                        : 'overlay-camera-data'
                    }`}>
                      {(camera.avg_activity !== null && camera.avg_activity !== undefined
                        ? `${Number(camera.avg_activity) === 0 ? '0' : (Number(camera.avg_activity) / 100).toFixed(1)}m` 
                        : '-'
                      )}
                    </span>
                  </div>
                  <div className="h-[90px] flex flex-row items-center justify-start">
                    <GeneralFork className="mx-16pxr w-50pxr h-50pxr" />
                    <span className={`${
                      camera.avg_feeding && 
                      monitoringData.avg_feeding && 
                      Number(camera.avg_feeding) < Number(monitoringData.avg_feeding) * 0.9
                        ? 'overlay-data-red' 
                        : 'overlay-camera-data'
                    }`}>
                      {(camera.avg_feeding !== null && camera.avg_feeding !== undefined
                        ? `${Number(camera.avg_feeding) === 0 ? '0' : Math.round(Number(camera.avg_feeding) / 60)}min` 
                        : '-'
                      )}
                    </span>
                  </div>
                  <div className="h-[90px] flex flex-row items-center justify-start">
                    <GeneralOW className="mx-16pxr w-50pxr h-50pxr" />
                    <span className={`${
                      camera.over_weight !== null && 
                      monitoringData.over_weight !== null && 
                      Number(camera.over_weight) > Number(monitoringData.over_weight)
                        ? 'overlay-data-red' 
                        : 'overlay-camera-data'
                    }`}>
                      O : {(camera.over_weight !== null && camera.over_weight !== undefined 
                        ? ` ${Number(camera.over_weight) === 0 ? '0' : Number(camera.over_weight).toFixed(1)}%` 
                        : ' -')}
                    </span>
                  </div>
                  <div className="h-[90px] flex flex-row items-center justify-start">
                    <GeneralUW className="mx-16pxr w-50pxr h-50pxr" />
                    <span className={`${
                      camera.under_weight !== null && 
                      monitoringData.under_weight !== null && 
                      Number(camera.under_weight) > Number(monitoringData.under_weight)
                        ? 'overlay-data-red' 
                        : 'overlay-camera-data'
                    }`}>
                      U : {(camera.under_weight !== null && camera.under_weight !== undefined 
                        ? ` ${Number(camera.under_weight) === 0 ? '0' : Number(camera.under_weight).toFixed(1)}%` 
                        : ' -')}
                    </span>
                  </div>
                  <div className="h-[90px] flex flex-row items-center justify-start">
                    <GeneralReportDeath className="mx-16pxr w-50pxr h-50pxr" />
                    <span className={`${
                      camera.disease_symptom !== null && 
                      monitoringData.disease_symptom !== null && 
                      Number(camera.disease_symptom) > Number(monitoringData.disease_symptom)
                        ? 'overlay-data-red' 
                        : 'overlay-camera-data'
                    }`}>
                      D : {(camera.disease_symptom !== null && camera.disease_symptom !== undefined 
                        ? ` ${Number(camera.disease_symptom) === 0 ? '0' : Number(camera.disease_symptom).toFixed(1)}%` 
                        : ' -')}
                    </span>
                  </div>
                  <div className="h-[90px] flex flex-row items-center justify-start">
                    <GeneralGnbMovement className="mx-16pxr w-50pxr h-50pxr" />
                    <span className={`${
                      camera.ship_recommend !== null && 
                      monitoringData.ship_recommend !== null && 
                      Number(camera.ship_recommend) > Number(monitoringData.ship_recommend)
                        ? 'overlay-data-red' 
                        : 'overlay-camera-data'
                    }`}>
                      T : {(camera.ship_recommend !== null && camera.ship_recommend !== undefined 
                        ? ` ${Number(camera.ship_recommend) === 0 ? '0' : Number(camera.ship_recommend).toFixed(1)}%` 
                        : ' -')}
                    </span>
                  </div>
                </div>
                <div className="h-[727px] w-[2px] bg-ef-neutral-200"></div>
                <div className="w-[300px] flex flex-col ml-24pxr">
                  <div className="h-[98px] flex flex-row items-center justify-start">
                    <GeneralRoom className="mr-16pxr w-50pxr h-50pxr" />
                    <span className="overlay-table-title">
                      Room (24h)
                    </span>
                  </div>
                  <div className="h-[90px] flex flex-row items-center justify-start">
                    <span className="overlay-room-data">
                      {(monitoringData.avg_weight ? `${Number(monitoringData.avg_weight).toFixed(1)}kg` : '-')}
                    </span>
                  </div>
                  <div className="h-[90px] flex flex-row items-center justify-start">
                    <span className="overlay-room-data">
                      {(monitoringData.avg_activity !== null && monitoringData.avg_activity !== undefined
                        ? `${Number(monitoringData.avg_activity) === 0 ? '0' : (Number(monitoringData.avg_activity) / 100).toFixed(1)}m` 
                        : '-'
                      )}
                    </span>
                  </div>
                  <div className="h-[90px] flex flex-row items-center justify-start">
                    <span className="overlay-room-data">
                      {(monitoringData.avg_feeding !== null && monitoringData.avg_feeding !== undefined
                        ? `${Number(monitoringData.avg_feeding) === 0 ? '0' : Math.round(Number(monitoringData.avg_feeding) / 60)}min` 
                        : '-'
                      )}
                    </span>
                  </div>
                  <div className="h-[90px] flex flex-row items-center justify-start">
                    <span className="overlay-room-data">
                      O : 
                      {(monitoringData.over_weight !== null && monitoringData.over_weight !== undefined 
                        ? ` ${Number(monitoringData.over_weight) === 0 ? '0' : Number(monitoringData.over_weight).toFixed(1)}%` 
                        : ' -'
                      )}
                    </span>
                  </div>
                  <div className="h-[90px] flex flex-row items-center justify-start">
                    <span className="overlay-room-data">
                      U : 
                      {(monitoringData.under_weight !== null && monitoringData.under_weight !== undefined 
                        ? ` ${Number(monitoringData.under_weight) === 0 ? '0' : Number(monitoringData.under_weight).toFixed(1)}%` 
                        : ' -'
                      )}
                    </span>
                  </div>
                  <div className="h-[90px] flex flex-row items-center justify-start">
                    <span className="overlay-room-data">
                      D : 
                      {(monitoringData.disease_symptom !== null && monitoringData.disease_symptom !== undefined 
                        ? ` ${Number(monitoringData.disease_symptom) === 0 ? '0' : Number(monitoringData.disease_symptom).toFixed(1)}%` 
                        : ' -'
                      )}
                    </span>
                  </div>
                  <div className="h-[90px] flex flex-row items-center justify-start">
                    <span className="overlay-room-data">
                      T : 
                      {(monitoringData.ship_recommend !== null && monitoringData.ship_recommend !== undefined 
                        ? ` ${Number(monitoringData.ship_recommend) === 0 ? '0' : Number(monitoringData.ship_recommend).toFixed(1)}%` 
                        : ' -'
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* top */}
        <div className="absolute inset-0 z-[4]">
          <div style={{ width: `${width}px`, height: `${height}px` }}>
            <div className="w-[1809px] h-[220px] mt-60pxr mx-60pxr">
              <div className="w-full h-full flex flex-col">
                <div className="flex flex-row justify-between">
                  <div>
                    <div className="overlay-title-container">
                      <div className="overlay-title-text">
                        {monitoringData.name} - {camera.name}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="w-full h-full flex flex-row gap-24pxr">
                      <div className={monitoringData.heat_index_abnormal ? 'overlay-title-container-red' : 'overlay-title-container'}>
                        <GeneralFlame className="w-50pxr h-50pxr" />
                        <div className="overlay-title-text">
                          {monitoringData.heat_index !== null
                            ? `${Math.round(monitoringData.heat_index)}`
                            : '-'
                          }
                        </div>
                      </div>
                      <div className={(() => {
                        if (monitoringData.today_temperature === null || monitoringData.yesterday_temperature === null) {
                          return 'overlay-title-container';
                        }
                        const diff = monitoringData.today_temperature - monitoringData.yesterday_temperature;
                        const isSignificantChange = Math.abs(diff) >= 5;
                        
                        return isSignificantChange ? 'overlay-title-container-red' : 'overlay-title-container';
                      })()}>
                        <GeneralTemperature className="w-50pxr h-50pxr" />
                        <div className="overlay-title-text">
                          {monitoringData.today_temperature !== null 
                            ? `${Number(monitoringData.today_temperature).toFixed(1)}°C` 
                            : '-'
                          }
                        </div>
                        <div className="overlay-sub-title-text">
                          {monitoringData.today_temperature !== null 
                            ? monitoringData.yesterday_temperature !== null
                              ? (() => {
                                  const diff = monitoringData.today_temperature - monitoringData.yesterday_temperature;
                                  return `(${diff > 0 ? '+' : ''}${diff === 0 ? '0' : diff.toFixed(1)}°C)`;
                                })()
                              : '(-)'
                            : ''
                          }
                        </div>
                      </div>
                      <div className={(() => {
                        if (monitoringData.today_humidity === null || monitoringData.yesterday_humidity === null) {
                          return 'overlay-title-container';
                        }
                        const diff = monitoringData.today_humidity - monitoringData.yesterday_humidity;
                        const isSignificantChange = Math.abs(diff) >= 10;
                        
                        return isSignificantChange ? 'overlay-title-container-red' : 'overlay-title-container';
                      })()}>
                        <GeneralRain className="w-50pxr h-50pxr" />
                        <div className="overlay-title-text">
                          {monitoringData.today_humidity !== null 
                            ? `${Number(monitoringData.today_humidity).toFixed(1)}%` 
                            : '-'
                          }
                        </div>
                        <div className="overlay-sub-title-text">
                          {monitoringData.today_humidity !== null 
                            ? monitoringData.yesterday_humidity !== null
                              ? (() => {
                                  const diff = monitoringData.today_humidity - monitoringData.yesterday_humidity;
                                  return `(${diff > 0 ? '+' : ''}${diff === 0 ? '0' : diff.toFixed(1)}%)`;
                                })()
                              : '(-)'
                            : ''
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-start mt-24pxr gap-24pxr">
                  <div className="overlay-title-container">
                    <GeneralCalendar className="w-50pxr h-50pxr" />
                    <div className="overlay-title-text">
                      {monitoringData.age !== null && monitoringData.age !== undefined
                        ? `${monitoringData.age}${
                            monitoringData.move_in_ago !== null && monitoringData.move_in_ago !== undefined
                              ? ` (+${monitoringData.move_in_ago})`
                              : ''
                          }`
                        : '-'
                      }
                    </div>
                  </div>
                  <div className="overlay-title-container">
                    <GeneralPig className="w-50pxr h-50pxr" />
                    <div className="overlay-title-text">
                      {camera.stock !== null && camera.stock !== undefined 
                        ? camera.stock 
                        : '-'
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default StreamOverlay;