import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { ReactComponent as ArrowDown } from "assets/icons/general/ef-general-arrowDown.svg";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import AddGroupDropDown from "components/pages/2_manage/stock/group/AddGroupDropDown";
import DropDownSmall from "components/common/dropdown/DropDownSmall";
import { differenceInDays, format } from "date-fns";
import { useAuthState } from "context/AuthContext";
import { toast } from "react-toastify";
import RequiredAlert from "components/common/alert/RequiredAlert";
import { useAddHerd, useHerdHistoryCategory } from "query/herd";
import { HerdPayload } from "query/herd/type";
import GroupSingleDatePicker from "./GroupSingleDatePicker";
import DatePicker from "react-datepicker";
import { RoomForStockDashBoard } from "query/piggery/type";
import { getRedStar } from "utils/getImportantStar";
import RoomDropDown from "components/pages/2_manage/device/feeder/RoomDropDown";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Props = {
  data?: RoomForStockDashBoard;
  envData?: any;
  roomId?: number;
  distinct: string;
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
};

const initialValue = {
  nickname: "",
  arrival_date: new Date(),
  birth_date: new Date(),
  room_id: 0,
  memo: "",
  history: {
    category_id: null,
    change: 0,
  },
  change: 0,
};

function AddGroupModal(props: Props) {
  const { t } = useTranslation();
  const { isToggle, setIsToggle, distinct, data, roomId, envData } = props;
  const [selectedTime, setSelectedTime] = useState(new Date());
  const { mutateAsync: addHerd } = useAddHerd();
  const { data: categoryList } = useHerdHistoryCategory();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<HerdPayload>({
    defaultValues: {
      room_id: roomId,
    },
  });

  const room_id = watch("room_id");
  const birth_date = watch("birth_date");
  const arrival_date = watch("arrival_date");
  const change = watch("history.change");

  const handleTimePickerChange = (time: Date) => {
    if (time) {
      setSelectedTime(time);
    }
  };

  const onClose = (value: CloseReason) => {
    if (value === "backdropClick") return;
    else {
      if (isDirty) {
        if (window.confirm(t("common.alert_not_saved"))) {
          setIsToggle(false);
          reset(initialValue);
        } else return;
      } else {
        setIsToggle(false);
        reset(initialValue);
      }
    }
  };

  const onSubmit = async (data: HerdPayload) => {
    const payload = {
      nickname: data.nickname ? data.nickname : null,
      birth_date: format(new Date(data.birth_date), "yyyy-MM-dd"),
      arrival_date: format(new Date(data.arrival_date), "yyyy-MM-dd") + "T" + format(selectedTime, "HH:mm:ss"),
      room_id: distinct === "group" ? data.room_id : roomId,
      memo: data.memo,
      history: {
        category_id: data.history.category_id ? data.history.category_id : null,
        change: data.history.change,
      },
    };

    try {
      await addHerd(payload);
      toast.success(t("common.save_completed"));
      setIsToggle(false);
      reset(initialValue);
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        toast.error(t("manage.add_one_herd_same_comment"));
      } else {
        toast.error(t("common.an_error_occurred"));
      }
      console.log(error);
    }
  };

  const getAgedDayFormat = () => {
    if (birth_date) return `yyyy-MM-dd (${differenceInDays(new Date(), new Date(birth_date))}'${t("common.aged")}')`;
  };

  useEffect(() => {
    reset(initialValue);
  }, [data]);

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center pt-120pxr overflow-y-auto pb-120pxr"
    >
      <div className="w-980pxr h-fit bg-ef-neutral-white outline-none flex flex-col p-40pxr">
        {/* header */}
        <div className="flex relative w-full">
          <span className="flex mx-auto ef-neutral-900 ef-title-lg">{t("common.add_herd")}</span>
          <button className="absolute right-0pxr" onClick={() => onClose("closeButtonClick")}>
            <GeneralClose className="stroke-ef-neutral-900" />
          </button>
        </div>
        {/* body */}
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
          <div className="my-60pxr flex flex-col flex-wrap gap-24pxr ef-label-md text-ef-neutral-900">
            <div className="flex flex-row items-center">
              <label className="w-77pxr min-w-[77px] line-clamp-1 mr-12pxr">{t("common.nickname")}</label>
              <div className="w-340pxr">
                <Controller
                  name="nickname"
                  control={control}
                  render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} placeholder={t("manage.nickname_placeholder")} maxLength={20} />}
                />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-77pxr min-w-[77px] mr-12pxr flex flex-row">
                <span className="line-clamp-1 break-all">{t("common.birth_date_aged_day")}</span>
                {getRedStar()}
              </label>
              <div className="w-full relative">
                {errors.birth_date ? <RequiredAlert /> : <></>}
                <div className="w-248pxr" onClick={() => clearErrors("birth_date")}>
                  <Controller
                    name="birth_date"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <GroupSingleDatePicker
                        date={new Date(field.value)}
                        setDate={(value) => {
                          field.onChange(value);
                        }}
                        placeholder={`YYYY-MM-DD (-${t("common.aged")})`}
                        dateFormat={getAgedDayFormat()}
                        maxDate={arrival_date ? new Date(arrival_date) : new Date()}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-77pxr min-w-[77px] mr-12pxr flex flex-row">
                <span className="line-clamp-1 break-all">{t("common.work_type")}</span>
                {getRedStar()}
              </label>
              <div className="w-full relative">
                {errors.history?.category_id ? <RequiredAlert /> : <></>}
                <div className="w-340pxr flex flex-col">
                  <Controller
                    name="history.category_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DropDownSmall
                        state={field.value}
                        setState={field.onChange}
                        placeholder={t("common.work_type")}
                        options={categoryList ? categoryList.filter((v) => v.id === 1 || v.id === 2).map((o) => ({ value: o.id, name: t(`common.herd_history_${o.id}`) })) : []}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-77pxr min-w-[77px] mr-12pxr flex flex-row">
                <span className="line-clamp-1 break-all">{t("common.work_datetime")}</span>
                {getRedStar()}
              </label>
              <div className="w-full flex flex-row relative">
                {errors.arrival_date ? <RequiredAlert /> : <></>}
                <div className="w-248pxr">
                  <Controller
                    name="arrival_date"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => <GroupSingleDatePicker date={new Date(field.value)} setDate={field.onChange} maxDate={new Date()} minDate={birth_date ? new Date(birth_date) : undefined} />}
                  />
                </div>
                <div
                  className={
                    "ml-8pxr group cursor-pointer rounded-[8px] border border-solid border-ef-neutral-150 w-144pxr h-40pxr py-8pxr px-16pxr flex flex-row items-center focus-within:border-ef-primary-500 text-ef-neutral-900 bg-ef-neutral-white"
                  }
                  onClick={(e) => e.preventDefault()}
                >
                  <DatePicker
                    selected={selectedTime}
                    onChange={handleTimePickerChange}
                    showTimeSelect
                    autoComplete="on"
                    showTimeSelectOnly
                    dateFormat="HH:mm"
                    placeholderText={t("common.time")}
                    timeIntervals={1}
                    timeCaption={t("common.time")}
                    onKeyDown={(e: any) => {
                      if (e.keyCode === 229) e.preventDefault();
                      e.preventDefault();
                    }}
                    customInput={
                      <button className="grow flex flex-row items-center">
                        <span className="grow w-88pxr text-left ef-body-lg">{format(selectedTime ? selectedTime : new Date(), "HH:mm")}</span>
                        <div className="w-24pxr h-24pxr mx-8pxr">
                          <ArrowDown className="stroke-ef-neutral-900 " />
                        </div>
                      </button>
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-77pxr min-w-[77px] mr-12pxr break-all line-clamp-1">
                {t("common.location")}
                {getRedStar()}
              </label>
              <div className="flex flex-row items-center relative w-full">
                {errors?.room_id ? <RequiredAlert /> : <></>}
                <div className="w-340pxr">
                  {distinct === "historyModal" || distinct === "envModal" ? (
                    <div
                      className={
                        "group ef-body-lg px-16pxr py-8pxr w-340pxr h-40pxr flex flex-row rounded-[8px] border border-solid border-ef-neutral-150  disabled:text-ef-neutral-500 bg-ef-neutral-50"
                      }
                    >
                      <span className={"overflow-hidden text-ellipsis whitespace-nowrap mr-8pxr break-all text-ef-neutral-300"}>
                        {distinct === "historyModal"
                          ? data?.name
                          : distinct === "envModal"
                            ? `[${envData?.piggery?.name ? envData?.piggery?.name : t("common.no_info")}] ${envData?.name ? envData?.name : t("common.no_info")}`
                            : t("common.location")}
                      </span>
                    </div>
                  ) : (
                      <Controller
                        name="room_id"
                        control={control}
                        rules={{ required: true, validate: (value) => Number(value) > 0 }}
                        render={({ field }) => (
                          <RoomDropDown
                            title={t("common.location")}
                            state={field.value}
                            setState={(value) => {
                              field.onChange(value.room);
                            }}
                          />
                        )}
                      />
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center">
              <label className="w-77pxr min-w-[77px] mr-12pxr flex flex-row">
                <span className="line-clamp-1 break-all">
                  {t("common.heads")}{getRedStar()}
                </span>
              </label>
              <div className="flex flex-row items-center relative w-full">
                {errors?.history?.change ? <RequiredAlert /> : <></>}
                <div className="w-300pxr">
                  <Controller
                    name="history.change"
                    control={control}
                    rules={{ required: true, validate: (value) => value > 0 }}
                    render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} type="number" placeholder={t("common.heads")} regex={/^(?:[1-9]\d*|0)?$/} />}
                  />
                </div>
                <span className="ml-4pxr">{t("common.head")}</span>
              </div>
            </div>
            <div className="flex flex-row">
              <label className="w-77pxr min-w-[77px] mr-12pxr flex flex-row mt-11pxr">
                <span className="line-clamp-1 break-all">{t("common.memo")}</span>
              </label>
              <div className="w-514pxr h-130pxr">
                <textarea {...register("memo")} className="ef-textarea w-full h-full" placeholder={t("common.enter_memo")}></textarea>
              </div>
            </div>
          </div>
          <button type="submit" className="ef-btn-modal mx-auto">
            {t("common.save")}
          </button>
        </form>
      </div>
    </Modal>
  );
}

export default AddGroupModal;
