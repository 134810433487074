import { useFarmDashboard } from "query/farm";
import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { ReactComponent as Stairs } from "assets/icons/general/ef-general-stairs.svg";
import { useTranslation } from "react-i18next";
import "./farmStatus.css";
import ComponentSpinner from "components/common/spinner/spinner";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { useNoticePopup } from "query/notice";
import NoticeModal from "components/pages/0_notice/NoticeModal";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    color: "#303030",
    boxShadow: "6px 6px 15px 0px rgba(14, 44, 74, 0.08)",
    maxWidth: "none",
    border: "1px solid #DEDEDE",
    borderRadius: "8px",
    padding: "16px",
    fontFamily: "Pretendard",
  },
}));

const divider = <div className="w-full h-1pxr bg-ef-neutral-150"></div>;
const miniDivider = <div className="w-1pxr h-8pxr bg-ef-neutral-200"></div>;

function PiggeryStatus() {
  const [selectedFloor, setSelectedFloor] = useState(1);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: farmData, isLoading } = useFarmDashboard();
  const { data: noticeData } = useNoticePopup();

  // piggery가 각각 몇층에 있는지 확인하고 중복제거
  const uniqueFloors = Array.from(new Set(farmData?.piggeries.map((p) => p.floor)));

  // 데이터 상태, 오늘하루 보지않기, 세션스토리지 상태에 따라 공지사항 모달 제공
  const getModalToggleState = () => {
    const enoughTodayId = getCookie("popupId");
    const sessionId = sessionStorage.getItem("popupId");

    if (typeof noticeData !== "undefined") {
      if (noticeData === null) return false;
      else {
        if (sessionId === null) {
          if (enoughTodayId === null) return true;
          else {
            if (Number(enoughTodayId) !== noticeData.id) return true;
            else return false;
          }
        } else {
          const parsedSession = Number(sessionId);
          if (parsedSession !== noticeData.id) return true;
          else return false;
        }
      }
    } else return false;
  };

  return (
    <div className="w-full h-full">
      {getModalToggleState() === true && <NoticeModal data={noticeData!} />}
      <div className="w-full h-[1080px] relative">
        {isLoading && <ComponentSpinner />}
        <img src={farmData ? farmData.image_path : ""} alt="farm-img" className="w-full h-full" />
        <div className="flex flex-row gap-16pxr absolute z-10 top-60pxr left-80pxr">
          {uniqueFloors.map((f) => (
            <button
              key={f}
              onClick={() => setSelectedFloor(f)}
              type="button"
              className={"ef-btn-map-dark " + (selectedFloor === f ? "!bg-ef-neutral-white !border-ef-neutral-150 !text-ef-primary-500 !stroke-ef-primary-500" : "")}
            >
              <Stairs />
              <span>{`${f}${t("status.floor")}`}</span>
            </button>
          ))}
        </div>
        <div className="bg-ef-neutral-white border border-solid border-ef-neutral-150 rounded-[8px] ef-main-shadow p-12pxr flex flex-col gap-8pxr absolute z-10 top-60pxr right-80pxr w-414pxr ef-body-sm text-ef-neutral-700">
          <div className="flex flex-row items-center justify-between">
            <span className="flex flex-row gap-4pxr items-center">
              <span>{t("status.stocks_in_farm")}</span>
              <span className="ef-body-md text-ef-primary-500">{`${farmData ? farmData.piggeries.reduce((acc, cur) => acc + cur.summary.today_stock, 0).toLocaleString() : 0}${t(
                "common.head",
              )}`}</span>
            </span>
            <span>{`${t("common.last_updated")} : ${format(new Date(), "MM-dd HH:mm")}`}</span>
          </div>
          <div className="w-full h-1pxr bg-ef-neutral-200"></div>
          <div className="flex flex-row flex-wrap items-center gap-8pxr whitespace-nowrap">
            {farmData &&
              farmData.piggeries.map((p, i) => (
                <>
                  {i !== 0 && <div className="w-1pxr h-10pxr bg-ef-neutral-200"></div>}
                  <span className="flex flex-row gap-4pxr">
                    <span>{p.name}</span>
                    <span className="text-ef-primary-500">{`${p.summary.today_stock.toLocaleString()}${t("common.head")}`}</span>
                  </span>
                </>
              ))}
          </div>
        </div>

        <svg className="absolute top-0pxr left-0pxr w-full h-full bg-ef-neutral-900 bg-opacity-80">
          {farmData?.piggeries
            .filter((o) => o.floor === selectedFloor)
            .map((p) => {
              const points = p.overlay.map((o: { x: number; y: number }) => ({
                x: o.x * 1920,
                y: o.y * 1080,
              }));
              const minY = Math.min(...points.map((point) => point.y));
              const maxY = Math.max(...points.map((point) => point.y));

              const polygonHeight = maxY - minY;

              return (
                <HtmlTooltip
                  key={p.id}
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, (polygonHeight / 2) * -1 + 10],
                        },
                      },
                    ],
                  }}
                  disableInteractive
                  title={
                    <div className="pointer-events-none">
                      <p className="ef-label-lg mb-8pxr text-ef-neutral-900 text-center">{p.name}</p>
                      {divider}
                      <div className="mt-16pxr flex flex-col gap-16pxr">
                        <div className="w-full gap-4pxr flex flex-col">
                          <div className="flex flex-row gap-16pxr items-center">
                            <span title={t("status.grade_1_apperanace")} className="w-114pxr max-w-[114px] ef-body-lg text-ef-neutral-700 line-clamp-1">
                              {t("status.grade_1_apperanace")}
                            </span>
                            <span className="text-ef-neutral-900 ef-body-xl">{t("common.preparing_now")}</span>
                          </div>
                          <div className="flex flex-row gap-16pxr items-center">
                            <span title={t("status.growing_rate")} className="w-114pxr max-w-[114px] ef-body-lg text-ef-neutral-700 line-clamp-1">
                              {t("status.growing_rate")}
                            </span>
                            <span className="text-ef-neutral-900 ef-body-xl">{p.summary.growing_rate === null ? t("common.no_info") : `${p.summary.growing_rate}%`}</span>
                          </div>
                          <div className="flex flex-row gap-16pxr items-center">
                            <span title={t("common.fcr")} className="w-114pxr max-w-[114px] ef-body-lg text-ef-neutral-700 line-clamp-1">
                              {t("common.fcr")}
                            </span>
                            <span className="text-ef-neutral-900 ef-body-xl">{t("common.preparing_now")}</span>
                          </div>
                        </div>
                        {divider}
                        <div className="w-full gap-4pxr flex flex-col">
                          <div className="flex flex-row gap-16pxr items-center">
                            <span title={t("common.number_of_stocks")} className="w-114pxr max-w-[114px] ef-body-lg text-ef-neutral-700 line-clamp-1">
                              {t("common.number_of_stocks")}
                            </span>
                            <span className="text-ef-neutral-900 ef-body-xl">{`${p.summary.today_stock}${t("common.head")}`}</span>
                          </div>
                          <div className="flex flex-row gap-16pxr items-center">
                            <span title={t("status.number_of_rooms")} className="w-114pxr max-w-[114px] ef-body-lg text-ef-neutral-700 line-clamp-1">
                              {t("status.number_of_rooms")}
                            </span>
                            <span className="text-ef-neutral-900 ef-body-xl">{p.summary.number_of_rooms === null ? t("common.no_info") : p.summary.number_of_rooms}</span>
                          </div>
                        </div>
                        {divider}
                        <div className="w-full gap-4pxr flex flex-col ef-body-lg text-ef-neutral-500">
                          <p className="ef-label-lg text-ef-neutral-900 mb-4pxr">{t("status.device_status")}</p>
                          <div className="flex flex-row gap-16pxr items-center">
                            <span className="w-114pxr max-w-[114px] ef-body-lg text-ef-neutral-700 line-clamp-1">EFC</span>
                            <div className="flex flex-row gap-8pxr items-center">
                              <div className="flex flex-row gap-2pxr">
                                <span>{t("status.total")}</span>
                                <span className="text-ef-neutral-900">{p.summary.number_of_efc_cameras + p.summary.number_of_efcr_cameras}</span>
                              </div>
                              {miniDivider}
                              <div className="flex flex-row gap-2pxr">
                                <span>{t("common.normal")}</span>
                                <span className="text-ef-state-green-500">
                                  {p.summary.number_of_efc_cameras + p.summary.number_of_efcr_cameras - (p.summary.number_of_disconnected_efc_cameras + p.summary.number_of_disconnected_efcr_cameras)}
                                </span>
                              </div>
                              {miniDivider}
                              <div className="flex flex-row gap-2pxr">
                                <span>{t("common.abnormal")}</span>
                                <span className="text-ef-state-red-500">{p.summary.number_of_disconnected_efc_cameras + p.summary.number_of_disconnected_efcr_cameras}</span>
                              </div>
                              {/* {miniDivider}
                              <div className="flex flex-row gap-2pxr">
                                <span>{t("status.angle_changed")}</span>
                                <span className="text-ef-state-red-500">0</span>
                              </div> */}
                            </div>
                          </div>
                          <div className="flex flex-row gap-16pxr items-center">
                            <span className="w-114pxr max-w-[114px] ef-body-lg text-ef-neutral-700 line-clamp-1">EFG</span>
                            <div className="flex flex-row gap-8pxr items-center">
                              <div className="flex flex-row gap-2pxr">
                                <span>{t("status.total")}</span>
                                <span className="text-ef-neutral-900">{p.summary.number_of_efg_cameras}</span>
                              </div>
                              {miniDivider}
                              <div className="flex flex-row gap-2pxr">
                                <span>{t("common.normal")}</span>
                                <span className="text-ef-state-green-500">{p.summary.number_of_efg_cameras - p.summary.number_of_disconnected_efg_cameras}</span>
                              </div>
                              {miniDivider}
                              <div className="flex flex-row gap-2pxr">
                                <span>{t("common.abnormal")}</span>
                                <span className="text-ef-state-red-500">{p.summary.number_of_disconnected_efg_cameras}</span>
                              </div>
                              {miniDivider}
                              <div className="flex flex-row gap-2pxr">
                                <span>{t("common.polluted")}</span>
                                <span className="text-ef-state-red-500">{p.summary.number_of_pollution_efg_cameras}</span>
                              </div>
                              {miniDivider}
                              <div className="flex flex-row gap-2pxr">
                                <span>{t('status.screen_abnormality')}</span>
                                <span className="text-ef-state-red-500">{p.summary.number_of_changed_angle_efg_cameras}</span>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-row gap-16pxr items-center">
                            <span title={t("common.feeder")} className="w-114pxr max-w-[114px] ef-body-lg text-ef-neutral-700 line-clamp-1">
                              {t("common.feeder")}
                            </span>
                            <div className="flex flex-row gap-8pxr items-center">
                              <div className="flex flex-row gap-2pxr">
                                <span>{t("status.total")}</span>
                                <span className="text-ef-neutral-900">{p.summary.number_of_feeders}</span>
                              </div>
                              {miniDivider}
                              <div className="flex flex-row gap-2pxr">
                                <span>{t("common.normal")}</span>
                                <span className="text-ef-state-green-500">{p.summary.number_of_feeders - p.summary.number_of_disconnected_feeders - p.summary.number_of_unlinked_feeders}</span>
                              </div>
                              {miniDivider}
                              <div className="flex flex-row gap-2pxr">
                                <span>{t("common.abnormal")}</span>
                                <span className="text-ef-state-red-500">{p.summary.number_of_disconnected_feeders}</span>
                              </div>
                              {miniDivider}
                              <div className="flex flex-row gap-2pxr">
                                <span>{t("common.not_supported")}</span>
                                <span className="text-ef-neutral-500">{p.summary.number_of_unlinked_feeders}</span>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-row gap-16pxr items-center">
                            <span title={t("common.environmental_sensor")} className="w-114pxr max-w-[114px] ef-body-lg text-ef-neutral-700 line-clamp-1">
                              {t("common.environmental_sensor")}
                            </span>
                            <div className="flex flex-row gap-8pxr items-center">
                              <div className="flex flex-row gap-2pxr">
                                <span>{t("status.total")}</span>
                                <span className="text-ef-neutral-900">{p.summary.number_of_sensors}</span>
                              </div>
                              {miniDivider}
                              <div className="flex flex-row gap-2pxr">
                                <span>{t("common.normal")}</span>
                                <span className="text-ef-state-green-500">{p.summary.number_of_sensors - p.summary.number_of_disconnected_sensors}</span>
                              </div>
                              {miniDivider}
                              <div className="flex flex-row gap-2pxr">
                                <span>{t("common.abnormal")}</span>
                                <span className="text-ef-state-red-500">{p.summary.number_of_disconnected_sensors}</span>
                              </div>
                              {miniDivider}
                              <div className="flex flex-row gap-2pxr">
                                <span>{t("common.suspected_breakdown")}</span>
                                <span className="text-ef-state-red-500">{p.summary.number_of_suspected_fault_sensors}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                >
                  <polygon
                    onClick={() => navigate(`/status/stock/${p.id}`)}
                    points={points.map((point) => `${point.x},${point.y}`).join(" ")}
                    style={{
                      stroke: "white",
                      strokeWidth: 2,
                      strokeDasharray: "5,5"
                    }}
                    className="farm-polygon"
                  />
                </HtmlTooltip>
              );
            })}
        </svg>
        {farmData?.piggeries.map((p) => {
          const points = p.overlay.map((o: { x: number; y: number }) => ({
            x: o.x * 1920,
            y: o.y * 1080,
          }));
          const centerX = points.reduce((acc, point) => acc + point.x, 0) / points.length;
          const centerY = points.reduce((acc, point) => acc + point.y, 0) / points.length;

          return (
            <div
              key={p.id}
              className={`absolute top-0pxr flex items-center justify-center ef-title-lg h-40pxr px-12pxr my-auto w-fit pointer-events-none rounded-[8px] bg-ef-neutral-white ${
                p.number_of_disconnected_device > 0 
                  ? 'text-ef-state-red-500 border border-ef-state-red-500 ' 
                  : 'text-ef-primary-500'
              }`}
              style={{
                top: centerY,
                left: centerX,
                transform: "translate(-50%, -50%)",
              }}
            >
              {p.name}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PiggeryStatus;

function getCookie(name: string): string | null {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }

  return null;
}
