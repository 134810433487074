import { differenceInMinutes, format } from "date-fns";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { ReactComponent as Paperclip } from "assets/icons/general/ef-general-paperclip.svg";
import axios from "axios";
import { useTranslation } from "react-i18next";

type Props = {
  selectedNotice: {
    id: number;
    type: string;
  };
  type: string;
  data: any;
  setSelectedNotice: React.Dispatch<React.SetStateAction<{ id: number; type: string }>>;
  index?: number;
  totalLength?: number;
};

function NoticeCard(props: Props) {
  const { selectedNotice, type, data, setSelectedNotice, index, totalLength } = props;
  const { i18n } = useTranslation();
  const [fileUrls, setFileUrls] = useState<Array<string | null>>([null, null, null]);

  const getLocalizedField = (field: 'title' | 'content') => {
    const lang = i18n.language;
    const fallbackLang = 'ko';
    
    const fieldKey = `${field}_${lang}` as keyof typeof data;
    const fallbackKey = `${field}_${fallbackLang}` as keyof typeof data;
    
    return data[fieldKey] || data[fallbackKey] || '';
  };

  useEffect(() => {
    if (data.file !== null && typeof data.file !== "undefined") {
      const files = JSON.parse(data.file);

      const preloadFiles = async () => {
        try {
          const results = await Promise.allSettled(
            files.map(async (file: string, index: number) => {
              const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/notice/${data.id}/${index}`, {
                responseType: "blob",
              });
              return window.URL.createObjectURL(new Blob([response.data]));
            }),
          );

          const urls = results.map((result) => {
            if (result.status === "fulfilled") {
              return result.value;
            } else {
              console.error(`Error preloading file at index ${result.reason}`);
              return null; // Handle error case, possibly set to null or a placeholder
            }
          });

          setFileUrls(urls);
        } catch (error) {
          console.error("Error preloading files:", error);
        }
      };
      preloadFiles();
    }
  }, [data.file]);

  const handleDownload = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, fileName: string, index: number) => {
    e.stopPropagation();
    const fileUrl = fileUrls[index];
    if (fileUrl) {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode!.removeChild(link);
    }
  };

  return (
    <div
      style={{
        backgroundColor: selectedNotice.id === data.id && selectedNotice.type === type ? "#F5FAFF" : "#FFFFFF",
      }}
      className="flex flex-col border-b border-ef-neutral-300 h-fit"
    >
      <div
        onClick={() => {
          if (type === "pin") {
            if (selectedNotice.id === data.id && selectedNotice.type === "pin") setSelectedNotice({ id: 0, type: "" });
            else setSelectedNotice({ id: data.id, type: "pin" });
          } else {
            if (selectedNotice.id === data.id && selectedNotice.type === "normal") setSelectedNotice({ id: 0, type: "" });
            else setSelectedNotice({ id: data.id, type: "normal" });
          }
        }}
        className="flex flex-row h-72pxr cursor-pointer"
      >
        <div className="h-full w-48pxr min-w-[48px] flex items-center justify-center flex-row">
          {type === "pin" ? (
            <div className="whitespace-nowrap py-2pxr px-8pxr w-fit text-ef-neutral-white bg-ef-primary-500 h-fit ef-label-md">{t("notice.fix")}</div>
          ) : (
            <span className="ef-label-md">{totalLength && typeof index !== "undefined" ? totalLength - index : ""}</span>
          )}
        </div>
        <div className="flex flex-row gap-8pxr px-8pxr tab:px-16pxr grow items-center">
          {differenceInMinutes(new Date(), new Date(data.notice_start_datetime)) < 10080 ? (
            <div className="px-8pxr py-2pxr min-w-[43px] items-center justify-center flex rounded-[10px] bg-ef-secondary-500 text-ef-neutral-white ef-label-sm">NEW</div>
          ) : (
            <></>
          )}
          <span className="line-clamp-1 ef-body-xl">{getLocalizedField('title')}</span>
          {data.file === null ? (
            <></>
          ) : (
            <div className="min-w-[24px]">
              <Paperclip className="stroke-ef-neutral-900" />
            </div>
          )}
        </div>
        <div className="whitespace-nowrap w-112pxr min-w-[112px] ml-auto ef-body-sm text-ef-neutral-500 flex items-center justify-end pr-16pxr">
          {format(new Date(data.notice_start_datetime), "yy-MM-dd")}
        </div>
      </div>
      {selectedNotice.id === data.id && selectedNotice.type === type ? (
        <div className="pl-80pxr pr-128pxr pb-40pxr border-t border-solid border-ef-neutral-150">
          {data.file !== null && (
            <div className="mt-16pxr rounded-[8px] p-16pxr bg-ef-neutral-white border border-solid border-ef-neutral-150 flex flex-row gap-16pxr">
              <span className="es-headline-h5 text-ef-neutral-900 whitespace-nowrap">{t("notice.attachment")}</span>
              <div className="flex flex-col gap-16pxr grow">
                {data.file !== null &&
                  typeof data.file !== "undefined" &&
                  JSON.parse(data.file).map((o: any, i: number) => (
                    <span
                      style={{
                        color: fileUrls[i] === null ? "#878787" : "#033687",
                      }}
                      className="break-all cursor-pointer es-link-sub"
                      key={i}
                      onClick={(e) => {
                        if (fileUrls[i] === null) alert(t("notice.download_failed"));
                        else handleDownload(e, o, i);
                      }}
                    >
                      {o}
                    </span>
                  ))}
              </div>
            </div>
          )}
          <div 
            className="ck-content es-ui-main mt-24pxr" 
            dangerouslySetInnerHTML={{ __html: getLocalizedField('content') }}
          ></div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default NoticeCard;
