import React, { useEffect, useState } from "react";
import { Modal, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { ReactComponent as Danger } from "assets/icons/icon16_general/ef-16general-danger.svg";
import { ReactComponent as ArrowDown } from "assets/icons/general/ef-general-arrowDown.svg";
import { Controller, useForm } from "react-hook-form";
import { HerdHistoryPayload } from "query/herd/type";
import { format } from "date-fns";
import { toast } from "react-toastify";
import RequiredAlert from "components/common/alert/RequiredAlert";
import DropDownSmall from "components/common/dropdown/DropDownSmall";
import DatePicker from "react-datepicker";
import { useAddHerdHistory, useHerdHistoryCategory } from "query/herd";
import GroupSingleDatePicker from "components/pages/2_manage/stock/group/GroupSingleDatePicker";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import { getRedStar } from "utils/getImportantStar";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Props = {
  herdData: any;
  herdId: number | undefined;
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  herdStock?: number;
};

type Inputs = {
  category_id: number | null;
  change?: number;
  stock?: number;
  created_at: Date;
  memo: string;
};

const initialValue = {
  category_id: null,
  change: 0,
  stock: 0,
  created_at: new Date(),
  memo: "",
};

function AddStockHistory(props: Props) {
  const { isToggle, setIsToggle, herdId, herdData, herdStock } = props;
  const { t } = useTranslation();
  const [selectedTime, setSelectedTime] = useState(new Date());

  const { mutateAsync: addHerdHistory } = useAddHerdHistory();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<Inputs>({ defaultValues: initialValue });

  const { data: categoryList } = useHerdHistoryCategory();

  const category_id = watch("category_id");
  const stock = watch("stock");
  const change = watch("change");

  const handleTimePickerChange = (time: Date) => {
    if (time) {
      setSelectedTime(time);
    }
  };

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    else {
      if (isDirty) {
        if (window.confirm(t("common.alert_not_saved"))) {
          setIsToggle(false);
          reset(initialValue);
        } else return;
      } else {
        setIsToggle(false);
        reset(initialValue);
      }
    }
  };

  const onSubmit = async (data: HerdHistoryPayload) => {
    const payload = {
      category_id: data.category_id,
      // 전출, 출하, 폐사일 경우 "-" 붙여줘야 함
      change: data.category_id === 3 || data.category_id === 4 || data.category_id === 5 ? -Number(data.change) : data.change,
      stock: data.stock,
      created_at: format(new Date(data.created_at!), "yyyy-MM-dd") + "T" + format(selectedTime, "HH:mm:ss"),
      memo: data.memo,
    };

    try {
      await addHerdHistory({ payload: payload, id: herdId! });
      toast.success(t("common.save_completed"));
      setIsToggle(false);
      reset(initialValue);
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        toast.error(t("status.cannot_add_history_same_herd_time_comment"));
      } else {
        toast.error(t("common.an_error_occurred"));
      }
    }
  };

  useEffect(() => {
    reset(initialValue);
  }, []);

  return (
    <>
      <Modal
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "#303030", // neutral-900
            opacity: "0.3 !important",
          },
        }}
        open={isToggle}
        onClose={(_, reason) => onClose(reason)}
        className="flex justify-center items-center overflow-y-auto"
      >
        <div className="w-[980px] bg-ef-neutral-white outline-none flex flex-col p-40pxr">
          <div className="flex relative w-full mb-24pxr">
            <span className="flex mx-auto ef-neutral-900 ef-title-lg">{t("status.add_herd_inventory_history")}</span>
            <button className="absolute right-0pxr" onClick={() => onClose("closeButtonClick")}>
              <GeneralClose className="stroke-ef-neutral-900" />
            </button>
          </div>
          <span className="ef-body-lg text-ef-neutral-700 w-full flex justify-center">{t("status.enter_history_occurred_room_comment")}</span>

          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
            <div className="my-60pxr flex flex-col flex-wrap gap-24pxr ef-label-md text-ef-neutral-900">
              <div className="flex flex-row items-center">
                <label className="w-77pxr min-w-[77px] mr-12pxr flex flex-row">
                  <span className="line-clamp-1 break-all">{t("common.work_type")}</span>
                  {getRedStar()}
                </label>
                <div className="w-full relative">
                  {errors.category_id ? <RequiredAlert /> : <></>}
                  <div className="w-340pxr flex flex-col">
                    <Controller
                      name="category_id"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <DropDownSmall
                          state={field.value}
                          setState={field.onChange}
                          placeholder={t("common.work_type")}
                          options={categoryList ? categoryList.map((o) => ({ value: o.id, name: t(`common.herd_history_${o.id}`) })) : []}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center">
                <label className="w-77pxr min-w-[77px] mr-12pxr flex flex-row">
                  <span className="line-clamp-1 break-all">
                    {category_id === null ? t("common.heads") : category_id === 6 ? t("common.number_of_stocks") : t("common.moved_stock")}
                    {getRedStar()}
                  </span>
                </label>
                <div className="flex flex-row items-center relative w-full">
                  {(category_id === 6 ? errors.stock : errors.change) ? <RequiredAlert /> : <></>}
                  {(category_id === 3 || category_id === 4 || category_id === 5) && errors.change ? (
                    <div className="absolute top-[-50%] z-[999] left-80pxr rounded-[8px] h-36pxr bg-[#FFF8F8] flex flex-row gap-4pxr items-center stroke-ef-secondary-500 text-ef-secondary-500 py-8pxr px-16pxr border border-solid border-ef-secondary-500 ef-sub-shadow ef-label-md whitespace-nowrap">
                      <Danger className="fill-ef-secondary-500" />
                      <span>{t("status.stocks_for_move_cannot_comment")}</span>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="flex flex-row items-center" onClick={() => clearErrors(category_id === 6 ? "stock" : "change")}>
                    <div className="w-300pxr flex flex-col gap-8pxr">
                      <Controller
                        name={category_id === 6 ? "stock" : "change"}
                        control={control}
                        rules={{
                          required: true,
                          validate: (value) => {
                            if (Number(value) === 0) {
                              return false;
                            }
                            if (category_id === 3 || category_id === 4 || category_id === 5) {
                              return Number(value) <= Number(herdStock); // 카테고리가 1 또는 2일 때만 검증
                            }
                            return true;
                          },
                        }}
                        render={({ field }) => (
                          <InputFieldOnly
                            state={field.value}
                            setState={(value) => {
                              field.onChange(value);
                            }}
                            type="number"
                            placeholder={t("common.heads")}
                            regex={/^(?:[1-9]\d*|0)?$/}
                          />
                        )}
                      />
                      {category_id === null ? (
                        ""
                      ) : category_id === 6 ? (
                        <span className="ef-body-xs text-ef-secondary-500">{t("status.initialize_room_entered_comment")}</span>
                      ) : (
                        <span className="ef-body-xs text-ef-secondary-500">※ {t("status.enter_stocks_moved_comment")}</span>
                      )}
                    </div>
                    <span className="ml-4pxr">{t("common.head")}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center">
                <label className="w-77pxr min-w-[77px] mr-12pxr flex flex-row">
                  <span className="line-clamp-1 break-all">{t("common.work_datetime")}</span>
                  {getRedStar()}
                </label>
                <div className="w-full flex flex-row relative">
                  {errors.created_at ? <RequiredAlert /> : <></>}
                  <div className="w-248pxr" onClick={() => clearErrors("created_at")}>
                    <Controller
                      name="created_at"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => <GroupSingleDatePicker minDate={new Date(herdData.arrival_date)} maxDate={new Date()} date={field.value} setDate={field.onChange} />}
                    />
                  </div>
                  <div
                    className={
                      "ml-8pxr group cursor-pointer rounded-[8px] border border-solid border-ef-neutral-150 w-144pxr h-40pxr py-8pxr px-16pxr flex flex-row items-center focus-within:border-ef-primary-500 text-ef-neutral-900 bg-ef-neutral-white"
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <DatePicker
                      selected={selectedTime}
                      onChange={handleTimePickerChange}
                      showTimeSelect
                      autoComplete="on"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      showTimeSelectOnly
                      placeholderText={t("common.time")}
                      timeCaption={t("common.time")}
                      timeIntervals={1}
                      onKeyDown={(e: any) => {
                        if (e.keyCode === 229) e.preventDefault();
                        e.preventDefault();
                      }}
                      customInput={
                        <button onClick={(e) => e.stopPropagation()} className="grow flex flex-row items-center">
                          <span className="grow w-88pxr text-left ef-body-lg">{format(selectedTime ? selectedTime : new Date(), "HH:mm")}</span>
                          <div className="w-24pxr h-24pxr mx-8pxr">
                            <ArrowDown className="stroke-ef-neutral-900 " />
                          </div>
                        </button>
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row">
                <label className="w-77pxr min-w-[77px] mr-12pxr flex flex-row mt-11pxr">
                  <span className="line-clamp-1 break-all">{t("common.memo")}</span>
                </label>
                <div className="w-514pxr h-130pxr" onClick={() => clearErrors("memo")}>
                  <textarea {...register("memo")} className="ef-textarea w-full h-full" placeholder={t("common.enter_memo")}></textarea>
                </div>
              </div>
            </div>
            <button type="submit" className="ef-btn-modal mx-auto">
              {t("common.save_btn")}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default AddStockHistory;
