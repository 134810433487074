import React from "react";
// components
import LanguageSelector from "components/common/lang/LanguageSelector";
//library
import { useNavigate } from "react-router-dom";
// svg
import { ReactComponent as GeneralLogout } from "assets/icons/general/ef-general-logout.svg";
// images
import NoImage1 from "assets/images/select/Farm Select_01.png";
import NoImage2 from "assets/images/select/Farm Select_02.png";
import NoImage3 from "assets/images/select/Farm Select_03.png";
import NoImage4 from "assets/images/select/Farm Select_04.png";
import { useTranslation, Trans } from "react-i18next";
import { useAuthDispatch, useAuthState } from "context/AuthContext";
import useLogout from "hooks/useLogout";
import client from "query/client";

const photoArr = [NoImage1, NoImage2, NoImage3, NoImage4];

function Select() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const memberInfo = useAuthState();
  const logout = useLogout();

  const authDispatch = useAuthDispatch();

  // 장소 선택시 context, localstorage, api header 설정하고 이동
  const handleFarmSelect = (farmId: number) => {
    const selectedFarm = memberInfo.user.farms.find((o) => o.id === farmId);
    if (selectedFarm) {
      authDispatch({
        type: "SET_FARM",
        payload: selectedFarm,
      });

      localStorage.setItem("farm-id", selectedFarm.id.toString());
      client.defaults.headers.common["farm-id"] = `${selectedFarm.id}`;
      navigate(`/`);
    }
  };

  return (
    <div className="w-full h-screen bg-ef-neutral-50 min-w-full lg:min-w-[1440px] overflow-x-auto">
      <div className="flex w-full h-fit flex-col">
        {/* header */}
        <div className="w-full h-[60px] lg:h-80pxr sticky top-0pxr bg-ef-neutral-50">
          <div className="w-full lg:w-[1440px] mx-auto flex flex-row">
            <div className="ml-auto my-8pxr lg:my-20pxr flex flex-row mr-8pxr lg:mr-0pxr">
              <div className="mr-8pxr">
                <LanguageSelector type="login" />
              </div>
              <button onClick={logout} className="flex flex-row ef-btn-r8-sm h-40pxr">
                <div className="flex items-center justify-center w-24pxr h-24pxr mr-8pxr">
                  <GeneralLogout />
                </div>
                <p>{t("signin.logout")}</p>
              </button>
            </div>
          </div>
        </div>
        {/* body */}
        <div className="grow w-full lg:w-[1440px] mx-auto px-16pxr lg:px-0pxr">
          <div className="w-full h-full flex-col items-center flex mb-100pxr lg:mb-200pxr">
            <p className="text-ef-neutral-900 ef-headline-md lg:ef-headline-lg mt-60pxr lg:mt-120pxr text-center">
              {t("select.select_your_farm")}
            </p>
            <p className="mt-8pxr lg:mt-16pxr text-ef-neutral-600 ef-body-md lg:ef-body-lg text-center px-16pxr">
              <Trans
                components={{ 
                  1: <span 
                    onClick={() => window.open("https://intflow.ai/contact/", "_blank")} 
                    className="text-ef-primary-500 ef-body-lg lg:ef-body-xl cursor-pointer" 
                  /> 
                }}
                i18nKey="select.click_contact_add"
              />
            </p>
            <div className={"w-full mt-40pxr lg:mt-60pxr flex items-center flex-wrap gap-x-16pxr lg:gap-x-36pxr gap-y-24pxr lg:gap-y-40pxr " + 
              (memberInfo.user.farms.length < 6 ? "justify-center" : "")}>
              {memberInfo.user.farms.map((o, i) => (
                <div
                  onClick={() => handleFarmSelect(o.id)}
                  key={i}
                  className="w-160pxr lg:w-210pxr h-240pxr lg:h-280pxr flex flex-col bg-ef-neutral-white rounded-[8px] border border-solid border-ef-neutral-150 ef-main-shadow px-8pxr pt-8pxr pb-10pxr cursor-pointer"
                >
                  <div className="w-144pxr lg:w-194pxr h-144pxr lg:h-194pxr bg-contain bg-no-repeat bg-center" 
                    style={{ backgroundImage: `url(${photoArr[i % photoArr.length]})` }}>
                  </div>
                  <div className="w-144pxr lg:w-194pxr h-60pxr items-center justify-center flex px-10pxr py-5pxr">
                    <span className="line-clamp-2">{o.name}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Select;
