import React from "react";
import NoDataImg from "assets/images/report/no_report_data.png";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useEditGrowCamAngle } from "query/efg";
import { toast } from "react-toastify";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  deviceType: "efc" | "efg" | "efcr";
  imgObj: {
    before_img: string | null | undefined;
    first_changed_img: string | null | undefined;
    current_img: string | null | undefined;
    first_changed_at: string | null | undefined;
    current_at: string | null | undefined;
  };
  deviceId: number;
};

function AngleChangeModal(props: Props) {
  const { isToggle, setIsToggle, deviceType, imgObj, deviceId } = props;

  const { t } = useTranslation();

  const { mutateAsync: confirmEfgAngle } = useEditGrowCamAngle();

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    else {
      setIsToggle(false);
    }
  };

  const handleConfirmAngle = async () => {
    try {
      if (deviceType === "efg") {
        await confirmEfgAngle(deviceId);
        toast.success(t("common.save_completed"));
        onClose("closeButtonClick");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center pt-120pxr overflow-y-auto pb-120pxr"
    >
      <div className="w-800pxr h-fit bg-ef-neutral-white outline-none flex flex-col py-40pxr px-24pxr">
        {/* header */}
        <div className="flex relative w-full">
          <span className="flex mx-auto ef-neutral-900 ef-title-lg">{t('common.check_screen_abnormality')}</span>
          <button className="absolute right-10pxr" onClick={() => onClose("closeButtonClick")}>
            <GeneralClose className="stroke-ef-neutral-900" />
          </button>
        </div>
        <p className="mt-20pxr ef-body-lg text-ef-neutral-700 text-center">{t('manage.screen_abnormality_comment')}</p>
        <div className="flex flex-row gap-16pxr mt-40pxr mx-auto">
          <div className="flex flex-col gap-8pxr">
            <div className="w-240pxr aspect-video relative">
              <div className="w-full h-full bg-ef-neutral-50 absolute top-0pxr rounded-[4px] flex flex-col items-center justify-center">
                <img src={NoDataImg} className="w-140pxr" alt="no-data" />
                <span className="ef-body-sm text-ef-neutral-700">{t("common.image_path_not_found")}</span>
              </div>
              <img 
                src={imgObj.before_img ?? ""} 
                alt="before-img" 
                className="w-full rounded-[4px] relative" 
                onError={(e) => (e.currentTarget.hidden = true)}
              />
            </div>
            <p className="ef-body-lg text-ef-neutral-700 text-center">{t('manage.image_before_screen_abnormality')}</p>
          </div>
          
          <div className="flex flex-col gap-8pxr">
            <div className="w-240pxr aspect-video relative">
              <div className="w-full h-full bg-ef-neutral-50 absolute top-0pxr rounded-[4px] flex flex-col items-center justify-center">
                <img src={NoDataImg} className="w-140pxr" alt="no-data" />
                <span className="ef-body-sm text-ef-neutral-700">{t("common.image_path_not_found")}</span>
              </div>
              <img 
                src={imgObj.first_changed_img ?? ""} 
                alt="first-changed-img" 
                className="w-full rounded-[4px] relative"
                onError={(e) => (e.currentTarget.hidden = true)} 
              />
            </div>
            <div className="flex flex-col gap-4pxr">
              <p className="ef-body-xl text-ef-primary-500 text-center">{t('manage.initial_screen_abnormality')}</p>
              <p className="ef-body-sm text-ef-neutral-500 text-center">
                {typeof imgObj.first_changed_at === "string" ? `(${format(new Date(imgObj.first_changed_at), "yyyy-MM-dd HH:mm:ss")})` : ""}
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-8pxr">
            <div className="w-240pxr aspect-video relative">
              <div className="w-full h-full bg-ef-neutral-50 absolute top-0pxr rounded-[4px] flex flex-col items-center justify-center">
                <img src={NoDataImg} className="w-140pxr" alt="no-data" />
                <span className="ef-body-sm text-ef-neutral-700">{t("common.image_path_not_found")}</span>
              </div>
              <img 
                src={imgObj.current_img ?? ""} 
                alt="current-img" 
                className="w-full rounded-[4px] relative"
                onError={(e) => (e.currentTarget.hidden = true)}
              />
            </div>
            <div className="flex flex-col gap-4pxr">
              <p className="ef-body-xl text-ef-primary-500 text-center">{t('manage.current_screen')}</p>
              <p className="ef-body-sm text-ef-neutral-500 text-center">
                {typeof imgObj.current_at === "string" ? `(${format(new Date(imgObj.current_at), "yyyy-MM-dd HH:mm:ss")})` : ""}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-8pxr justify-center mt-36pxr">
          <button onClick={() => onClose("closeButtonClick")} type="button" className="ef-btn-modal-delete !w-244pxr">
            {t("manage.later")}
          </button>
          <button onClick={() => handleConfirmAngle()} type="button" className="ef-btn-modal !w-244pxr">
            {t("manage.confirm")}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default AngleChangeModal;
