import React from "react";
import { ReactComponent as LogoText } from "assets/logo/ef-footer-logo.svg";
import { ReactComponent as LinkedIn } from "assets/icons/footer/ef-footer-linkedin.svg";
import { ReactComponent as Youtube } from "assets/icons/footer/ef-footer-youtube.svg";
import { ReactComponent as Intflow } from "assets/icons/footer/ef-footer-intflow.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useReleasePageNum, useReleaseVersion } from "query/notice";

function Footer() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { data: releaseData } = useReleaseVersion();
  const { data: pageData } = useReleasePageNum(releaseData ? releaseData.notice_id : 0);

  return (
    <div className="w-full h-fit px-100pxr bg-ef-primary-900 flex flex-col">
      <div className="w-full h-full flex flex-col mt-47pxr flex-wrap gap-24pxr">
        {/* navigate */}
        <div className="w-full flex flex-row items-center justify-between">
          <div className="flex flex-row ef-body-xl text-ef-neutral-white">
            <span className="mr-29pxr cursor-pointer" onClick={() => window.open("https://intflowuserguide.oopy.io/95a876d0-0ffe-4ee4-9468-7682ad9b666f", "_blank")}>
              {t("footer.faq")}
            </span>
            {/* <span className="mr-33pxr cursor-pointer" onClick={() => window.open("/contact", "_blank")}> */}
            <span className="mr-33pxr cursor-pointer" onClick={() => window.open("https://intflow.ai/contact/", "_blank")}>
              {t("footer.contact")}
            </span>
            <span className="cursor-pointer" onClick={() => navigate("/notice")}>
              {t("footer.notice")}
            </span>
          </div>
          <div className="flex flex-row items-center gap-4pxr">
            {releaseData && (
              <>
                <span className="text-ef-neutral-white ef-body-lg">{releaseData.version}</span>
                <span
                  onClick={() =>
                    navigate("/notice", {
                      state: {
                        pageNum: pageData ? pageData.page_number : 1,
                        noticeId: pageData ? pageData.notice_id : 0,
                      },
                      replace: true,
                    })
                  }
                  className="text-ef-neutral-white ef-label-lg underline underline-offset-4 cursor-pointer"
                >
                  {t("notice.view_more")}
                </span>
              </>
            )}
          </div>
        </div>
        {/* divider */}
        <div className="w-full border-t border-solid border-ef-neutral-500"></div>
        {/* info */}
        <div className="flex flex-row">
          <div className="flex flex-col">
            <div className="w-147pxr h-32pxr cursor-pointer" onClick={() => window.open("https://edgefarm.ai", "_blank")}>
              <LogoText className="fill-ef-neutral-white" />
            </div>
            <div className="mt-30pxr ef-body-lg text-ef-neutral-white flex flex-col">
              <span>{t("footer.ceo")}</span>
              <span>{t("footer.business_number")}</span>
              <span>{t("footer.address")}</span>
              <span>{t("footer.email")}</span>
            </div>
            <div className="flex flex-row mt-24pxr text-ef-neutral-white ef-label-md">
              <span onClick={() => alert("별도 지류계약서를 확인해주세요.")} className="cursor-pointer mr-24pxr">
                {t("footer.terms_of_use")}
              </span>
              <span onClick={() => alert("별도 지류계약서를 확인해주세요.")} className="cursor-pointer">
                {t("footer.privacy_policy")}
              </span>
            </div>
          </div>
          <div className="ml-auto grid grid-cols-3 gap-8pxr mt-8pxr">
            <div
              onClick={() => window.open("https://www.linkedin.com/company/intflow/", "_blank")}
              className="w-40pxr h-40pxr flex items-center justify-center bg-ef-neutral-white bg-opacity-10 rounded-[40px] cursor-pointer"
            >
              <LinkedIn />
            </div>
            <div
              onClick={() => window.open("https://www.youtube.com/@intflowinc.1223/featured", "_blank")}
              className="w-40pxr h-40pxr flex items-center justify-center bg-ef-neutral-white bg-opacity-10 rounded-[40px] cursor-pointer"
            >
              <Youtube />
            </div>
            <div
              onClick={() => window.open("https://intflow.ai/", "_blank")}
              className="w-40pxr h-40pxr flex items-center justify-center bg-ef-neutral-white bg-opacity-10 rounded-[40px] cursor-pointer"
            >
              <Intflow />
            </div>
          </div>
        </div>
      </div>
      <span className="mt-40pxr mb-49pxr text-ef-neutral-400 ef-body-xs mx-auto">© 2024. intflow Inc. All rights reserved.</span>
    </div>
  );
}

export default Footer;
