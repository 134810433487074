import React, { useEffect, useMemo, useRef, useState } from "react";
// icons && assets
import "./css/calendar.css";
import { css } from "@emotion/react";
import WriteReport from "assets/images/report/writeReport.png";
import animationData from "assets/images/spinner/spinner_animation.json";
import { ReactComponent as ArrowLeft } from "assets/icons/general/ef-general-arrowLeft.svg";
import { ReactComponent as ArrowRight } from "assets/icons/general/ef-general-arrowRight.svg";
import { ReactComponent as Calendar } from "assets/icons/report/ef-report-calendar.svg";
import { ReactComponent as Arrival } from "assets/icons/report/ef-report-arrival.svg";
import { ReactComponent as Shipment } from "assets/icons/report/ef-report-shipment.svg";
import { ReactComponent as Death } from "assets/icons/report/ef-report-death.svg";
import { ReactComponent as Movement } from "assets/icons/gnb/ef-gnb-movement.svg";
import { ReactComponent as Danger } from "assets/icons/general/ef-general-bold-danger.svg";
import { ReactComponent as Unknown } from "assets/icons/report/ef-report-unknown.svg";
import { ReactComponent as Write } from "assets/icons/report/ef-report-reportWrite.svg";
import { ReactComponent as Refresh } from "assets/icons/general/ef-general-refresh.svg";
import { ReactComponent as StarOn } from "assets/graphic/ef-graphic-star-big-on.svg";
import { ReactComponent as StarOff } from "assets/graphic/ef-graphic-star-big-off.svg";

import Illust from "assets/graphic/ef-graphic-illust-image.svg";

// query && context
import { usePiggeryStructure } from "query/piggery";
import { MovementList } from "query/movement/type";
import { useReportContext } from "context/reportContext";
import { useMovementCategories, useMovementDetail, useMovementList, useMovementSummary } from "query/movement";
// libs
import Lottie from "lottie-react";
import listPlugin from "@fullcalendar/list";
import DatePicker from "react-datepicker";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useTranslation } from "react-i18next";
import { EventClickArg } from "@fullcalendar/core";
import { format, lastDayOfMonth, startOfMonth } from "date-fns";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
// components
import BtnSort from "components/common/button/BtnSort";
import DropDownFilter from "components/common/dropdown/DropDownFilter";
import DropDownCheckBox from "components/common/dropdown/DropDownCheckBox";
// import AddRoomOrGroupDropDown from "components/pages/reports/move/AddRoomOrGroupDropDown";
import NewDetailReport from "components/pages/2_manage/stock/movement/detail/report/detailReport";
import NoEnteredAgeDetailReport from "components/pages/2_manage/stock/movement/detail/report/noEnteredAgeDetailReport";
// import NoWeightListReport from "components/pages/2_manage/stock/movement/detail/report/noWeightListReport";
// import NoBirthNoWeightListReport from "components/pages/2_manage/stock/movement/detail/report/noBirthnoWeightListReport";
// import HandWritingReport from "components/pages/2_manage/stock/movement/detail/report/handWritingReport";
import MovementModal from "components/pages/2_manage/stock/movement/handWriting/movementModal";
import { useRoomDropdownList } from "query/rooms";
import { PiggeryStructure } from "query/piggery/type";
import { datePickerLocale } from "utils/useDatepicker";
import { useLocation } from "react-router-dom";
import { RatingDisplay } from "components/pages/2_manage/stock/movement/detail/util/ratingDisplay";

type ProcessedDataItem = {
  count: number;
  totalCount: number;
};

export type ReportType = {
  id: number;
  name: string;
};

interface TransformedData {
  title: string;
  start: string;
  property: number;
}

type ProcessedData = Record<string, Record<number, ProcessedDataItem[]>>;

export default function ReportMovement() {
  const { t } = useTranslation();
  const calendarRef = useRef<FullCalendar | null>(null);

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [piggery, setPiggery] = useState<number[]>([]);
  const [room, setRoom] = useState<number[]>([]);
  const [work, setWork] = useState<number[]>([]);
  const [auto, setAuto] = useState<number[]>([]);
  const [reportType, setReportType] = useState<ReportType>({ id: 0, name: "" });
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [result, setResult] = useState<TransformedData[]>();
  // 선택한 보고서
  const [selectedId, setSelectedId] = useState<number>(0);
  const [handWriteModal, setHandWriteModal] = useState(false);
  // detail toggle
  const [detailToggle, setDetailToggle] = useState(false);
  // 출생일 없을 때
  const [birthToggle, setBirthToggle] = useState(false);
  // 체중목록 없을 때
  const [weightToggle, setWeightToggle] = useState(false);
  // 둘 다 없을 때
  const [noToggle, setNoToggle] = useState(false);
  // const { data: piggeryList } = usePiggeryList();
  // const { data: piggeryData } = usePiggeryDetail(piggery.length > 0 ? piggery : 0, false);
  // const { data: reportData } = useMovementDetail(report_num ? Number(report_num) : selectedId.id);
  const { data: reportData } = useMovementDetail(selectedId);
  const { data: workList } = useMovementCategories();
  // const [movementList, setMovementList] = useState<any[]>([]);
  // const [isLoading, setIsLoading] = useState(false);
  // const [workList, setWorkList] = useState<any[]>([]);
  // const [piggeryList, setPiggeryList] = useState<any[]>([]);
  const categories = [1, 2];
  const { data: piggeryList } = useRoomDropdownList(piggery, categories);
  const { data: piggeryStructure } = usePiggeryStructure();
  const firstDateOfMonth = startOfMonth(new Date(selectedDate));
  const formattedFirstDate = format(firstDateOfMonth, "yyyy-MM-dd");
  const lastDateOfMonth = lastDayOfMonth(new Date(selectedDate));
  const formattedLastDate = format(lastDateOfMonth, "yyyy-MM-dd");

  const { data: movementSummary } = useMovementSummary(format(selectedDate, "yyyy-MM"), work, piggery, room, auto);
  const { data: movementList, isLoading } = useMovementList(format(selectedDate, "yyyy-MM-dd"), work, piggery, room, auto);
  const filteredReportList = movementList && movementList.filter((report) => format(new Date(report.started_at!), "yyyy-MM-dd") === format(new Date(selectedDate), "yyyy-MM-dd"));
  const { isReportCreated, workDate } = useReportContext();
  const [thumbnailErrors, setThumbnailErrors] = useState<number[]>([]);

  const handleSortClick = () => {
    setSortOrder((prevSortOrder) => (prevSortOrder === "asc" ? "desc" : "asc"));
  };

  // 오름차순 내림차순 필터링
  const sortedReportList = useMemo(() => {
    if (filteredReportList) {
      return [...filteredReportList].sort((a, b) => {
        const dateA = new Date(a.started_at!).getTime();
        const dateB = new Date(b.started_at!).getTime();

        return sortOrder === "desc" ? dateA - dateB : dateB - dateA;
      });
    }
    return [];
  }, [filteredReportList, sortOrder]);

  // 소요시간 계산 fn
  const calculateDuration = (startTime: Date, endTime: Date) => {
    const durationInMinutes = (endTime.getTime() - startTime.getTime()) / (1000 * 60);

    if (!isNaN(durationInMinutes)) {
      return `(${t("manage.about_mins", { min: Math.round(durationInMinutes) })})`;
    }

    return "";
  };

  // 시간대 다국어
  const getTimeLocale = (date: Date) => {
    const locale = localStorage.getItem("i18nextLng");
    switch (locale) {
      case "en":
        return format(date, "hh:mm aa");
      case "ja":
      case "ko":
        return format(date, "aa hh:mm", { locale: datePickerLocale() });
    }
  };

  // 입,출,전,폐 구분 fn
  const renderWorkStatus = (work: number, cnt: number) => {
    switch (work) {
      case 1:
        return (
          <span className="bg-ef-state-blue-500 p-4pxr pr-8pxr rounded-md flex flex-row mb-10pxr items-center justify-center">
            <Arrival className="stroke-ef-neutral-white mr-4pxr" />

            <span className="ef-body-sm text-ef-neutral-white flex flex-row items-center">
              <span className="mx-4pxr">{t("common.arrival")}</span>
              <span className="ef-body-md">{cnt + t("common.head")}</span>
            </span>
          </span>
        );

      case 3:
        return (
          <span className="bg-ef-state-green-500 p-4pxr pr-8pxr rounded-md flex flex-row mb-10pxr items-center justify-center">
            <Shipment className="stroke-ef-neutral-white mr-4pxr" />

            <span className="ef-body-sm text-ef-neutral-white flex flex-row items-center">
              <span className="mx-4pxr">{t("common.shipment")}</span>
              <span className="ef-body-md">{cnt + t("common.head")}</span>
            </span>
          </span>
        );

      case 4:
        return (
          <span className="bg-ef-state-red-500 p-4pxr pr-8pxr rounded-md flex flex-row mb-10pxr items-center justify-center">
            <Death className="stroke-none mr-4pxr" />

            <span className="ef-body-sm text-ef-neutral-white flex flex-row items-center">
              <span className="mx-4pxr">{t("common.death")}</span>
              <span className="ef-body-md">{cnt + t("common.head")}</span>
            </span>
          </span>
        );

      case 2:
        return (
          <span className="bg-ef-state-cyan-500 p-4pxr pr-8pxr rounded-md flex flex-row mb-10pxr items-center justify-center">
            <Movement className="stroke-ef-neutral-white mr-4pxr" />
            <span className="ef-body-sm text-ef-neutral-white flex flex-row items-center">
              <span className="mx-4pxr">{t("common.internal_movement")}</span>
              <span className="ef-body-md">{cnt + t("common.head")}</span>
            </span>
          </span>
        );

      default:
        return (
          <span className="bg-ef-neutral-500 p-4pxr pr-8pxr rounded-md flex flex-row mb-10pxr items-center justify-center">
            <Unknown className="stroke-ef-neutral-white" />
            <span className="ef-body-sm text-ef-neutral-white flex flex-row items-center">
              <span className="mx-4pxr">{t("common.unknown")}</span>
              <span className="ef-body-md">{cnt + t("common.head")}</span>
            </span>
          </span>
        );
    }
  };

  // event(캘린더 표출)
  const eventContent = ({ event }: any) => {
    const getPropertyColorClass = () => {
      const property = event._def?.extendedProps?.property;

      if (property === 4) {
        return "#CC3340";
      } else if (property === 1) {
        return "#1F73B7";
      } else if (property === 2) {
        return "#038153";
      } else if (property === 3) {
        return "#1098AD";
      } else {
        return "#878787";
      }
    };

    if (event.start) {
      const bgColorClass = getPropertyColorClass();

      return (
        <div className="flex items-center justify-center w-full">
          <span
            css={css`
              background-color: ${bgColorClass};
            `}
            className={`rounded-full w-8pxr h-8pxr mr-8pxr my-4pxr`}
          ></span>
          <span
            css={css`
              color: ${bgColorClass};
            `}
            className={`ef-body-md w-65pxr`}
          >
            {event?.title}
          </span>
        </div>
      );
    }
  };

  // 캘린더 내 클릭된 cell css
  const handleCellClassNames = (info: any) => {
    if (new Date(selectedDate) && info.date) {
      const cellDate = new Date(info.date.setHours(0, 0, 0, 0));
      const clickedDate = new Date(new Date(selectedDate).setHours(0, 0, 0, 0));

      if (cellDate.getTime() === clickedDate.getTime()) {
        return ["clicked-cell"];
      }
    }
    return [];
  };

  // 달력 제목
  const handleDatePickerChange = (date: Date) => {
    if (date) {
      const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      setSelectedDate(firstDayOfMonth);
    }
  };

  // 보고서 목록 클릭 이벤트
  const handleReportClick = (info: MovementList) => {
    setDetailToggle(true);
    setSelectedId(info?.id);
    // navigate(`?report_date=${selectedDate ? format(new Date(selectedDate), "yyyy-MM-dd") : format(new Date(), "yyyy-MM-dd")}&report_num=${info.id}`);
  };

  // 캘린더 날짜 클릭이벤트
  const handleDateClick = (info: DateClickArg | EventClickArg) => {
    if ("dateStr" in info) {
      const clickedDate = new Date(info.dateStr);
      // navigate(`?report_date=${format(clickedDate, "yyyy-MM-dd")}`);
      clickedDate.setHours(0, 0, 0, 0);
      setSelectedDate(clickedDate);
    } else {
      if (info?.event?.start) {
        const clickedDate = new Date(info?.event?.start);
        // navigate(`?report_date=${format(clickedDate, "yyyy-MM-dd")}`);
        clickedDate.setHours(0, 0, 0, 0);

        setSelectedDate(clickedDate);
      }
    }
  };

  // 달력 좌,우 핸들러
  const handlePrevClick = () => {
    if (calendarRef.current) {
      (calendarRef.current as FullCalendar).getApi().prev();
      const newDate = (calendarRef.current as FullCalendar).getApi().view.currentStart;
      setSelectedDate(newDate);
      console.log(newDate);
    }
  };

  const handleNextClick = () => {
    if (calendarRef.current) {
      (calendarRef.current as FullCalendar).getApi().next();
      const newDate = (calendarRef.current as FullCalendar).getApi().view.currentStart;
      setSelectedDate(newDate);
    }
  };

  // 썸네일 이미지 표출 오류 핸들러
  const onErrorHandler = (e: any, id: number) => {
    setThumbnailErrors((prevErrors) => [...prevErrors, id]);
    e.target.src = require(`assets/images/report/generate_img.png`);
  };

  const piggeryState = useMemo(() => {
    if (piggeryStructure)
      return piggeryStructure.map((o: PiggeryStructure) => {
        return { value: o.id, name: o.name };
      });
    else return [];
  }, [piggeryList]);

  // const roomState = useMemo(() => {
  //   if (piggeryList)
  //     return piggeryList.map((o) => {
  //       return { value: o.id, name: o.name };
  //     });
  //   else return [];
  // }, [piggeryList]);

  const workState = useMemo(() => {
    if (workList)
      return workList.map((o) => {
        return { value: o.id, name: t(`common.movement_category_${o.id}`) };
      });
    else return [];
  }, [workList]);

  // 캘린더용 데이터 setState
  useEffect(() => {
    const transformData = (data: any[]): TransformedData[] => {
      return data.flatMap((movement) =>
        movement.summary.map((summaryItem: { total_stock: any; number_of_movement: string; category: { id: any } }) => ({
          title: `${summaryItem.total_stock} (${summaryItem.number_of_movement + t("manage.cases")})`,
          start: movement.date,
          property: summaryItem.category.id,
        })),
      );
    };

    if (movementSummary) {
      const data = transformData(movementSummary);
      setResult(data);
    }
  }, [movementSummary, selectedDate]);

  useEffect(() => {
    if (isReportCreated) {
      if (movementList && movementList.length > 0) {
        setSelectedDate(new Date(movementList[0].started_at!));
      }
    }
  }, [isReportCreated, movementList]);

  useEffect(() => {
    if (isReportCreated) {
      if (workDate) {
        setSelectedDate(workDate);
      }
    }
  }, [isReportCreated]);

  // searchParams 나중에 사용할 듯? 알림톡 때 사용할 거 같아서 우선 남겨둠
  // useEffect(() => {
  //   console.log(report_date, report_num);
  //   if (report_date && report_num) {
  //     setSelectedDate(new Date(report_date));
  //     setDetailToggle(true);
  //   } else {
  //     setDetailToggle(false);
  //   }
  // }, [searchParams]);

  return (
    <>
      {handWriteModal && <MovementModal isToggle={handWriteModal} setIsToggle={setHandWriteModal} />}

      {/* 필터링 */}
      <div className="w-full px-40pxr py-52pxr flex justify-between border border-t-transparent border-l-transparent border-r-transparent border-solid border-ef-neutral-200 h-fit">
        <div className="flex flex-row items-center">
          <span className="ef-headline-lg mr-16pxr">{t("gnb.movement_record")}</span>
        </div>
        <div className="flex flex-row">
          <div className="flex mr-24pxr items-center">
            {workList && <div className="mr-8pxr">{<DropDownCheckBox title={t("common.work_type")} options={
              workList
                ? workList.map((o) => {
                  return { value: o.id, name: t(`common.movement_category_${o.id}`) };
                })
                : []
            } state={work} setState={setWork} />}</div>}
            <div className="flex items-center mr-8pxr">{piggeryStructure && <DropDownCheckBox title={t("common.piggery")} options={piggeryState} state={piggery} setState={setPiggery} />}</div>
            {/* <div className="flex  mr-8pxr">{piggeryStructure && <DropDownCheckBox title={t("report.select_room")} options={roomState} state={room} setState={setRoom} />}</div> */}
          </div>
          <button
            className="ef-btn-reset flex flex-row flex-wrap gap-4pxr items-center"
            onClick={() => {
              setPiggery([]);
              setRoom([]);
              setWork([]);
              setSelectedId(0);
            }}
          >
            <Refresh />
            {t("common.reset")}
          </button>
        </div>
        {/* 필터링 끝 */}
      </div>

      <div className="w-full flex h-full">
        <div className="w-[1178px] px-40pxr h-full">
          {/* 보고서 상세 */}
          {
            // EFC 일령(X) 보고서
            detailToggle && !reportData?.age ? (
              //  reportData && !reportData?.herd?.birth_date
              <NoEnteredAgeDetailReport selectedId={selectedId} data={movementList} isToggle={detailToggle} setIsToggle={setDetailToggle} />
            ) : // ) : // 체중목록이 없는 경우
            // detailToggle && reportData && (reportData?.unit_details.length === 0 || !reportData?.unit_details.length) ? (
            //   <NoWeightListReport reportData={reportData} isToggle={detailToggle} setIsToggle={setDetailToggle} />
            // ) : // 체중목록과 일령이 없는 경우
            // detailToggle && reportData && (reportData?.unit_details.length === 0 || !reportData?.unit_details.length) && !reportData?.herd?.birth_date ? (
            //   <NoBirthNoWeightListReport reportData={reportData} isToggle={detailToggle} setIsToggle={setDetailToggle} />
            detailToggle && reportData?.age ? (
              // EFC 일령(O) 보고서
              <NewDetailReport reportData={reportData} isToggle={detailToggle} setIsToggle={setDetailToggle} />
            ) : (
              <>
                <div className="flex justify-between w-full mt-40pxr items-center">
                  {/* 캘린더 제목 */}
                  <div className="flex items-center justify-center mb-24pxr">
                    <button className="bg-ef-neutral-white border border-solid p-16pxr h-60pxr rounded-md mr-8pxr" onClick={() => handlePrevClick()}>
                      <ArrowLeft className="stroke-ef-neutral-900" />
                    </button>
                    <span className="flex items-center justify-center bg-ef-neutral-white w-200pxr h-60pxr text-center border border-solid rounded-md ef-title-md">
                      <div className="z-10">
                        <DatePicker
                          selected={selectedDate}
                          onChange={handleDatePickerChange}
                          showMonthYearPicker
                          autoComplete="on"
                          customInput={
                            <button className="flex items-center justify-center">
                              <span className="mr-8pxr">{format(selectedDate ? selectedDate : new Date(), "yyyy-MM")}</span>
                              <Calendar className="stroke-ef-neutral-900" />
                            </button>
                          }
                        />
                      </div>
                    </span>
                    <button className="bg-ef-neutral-white border border-solid p-16pxr h-60pxr rounded-md ml-8pxr" onClick={() => handleNextClick()}>
                      <ArrowRight className="stroke-ef-neutral-900" />
                    </button>
                  </div>

                  <div className="flex mb-24pxr">
                    <div className="flex">
                      <span className="pl-4pxr pr-8pxr py-4pxr mr-8pxr flex items-center rounded-[4px] text-ef-neutral-white ef-body-sm bg-ef-state-blue-500">
                        <Arrival className="stroke-ef-neutral-white mr-4pxr" /> {t("common.arrival")}
                      </span>
                      <span className="pl-4pxr pr-8pxr py-4pxr mr-8pxr flex items-center rounded-[4px] text-ef-neutral-white ef-body-sm bg-ef-state-green-500">
                        <Shipment className="stroke-ef-neutral-white mr-4pxr" /> {t("common.shipment")}
                      </span>
                      <span className="pl-4pxr pr-8pxr py-4pxr mr-8pxr flex items-center rounded-[4px] text-ef-neutral-white ef-body-sm bg-ef-state-red-500">
                        <Death className="stroke-none mr-4pxr" />
                        {t("common.death")}
                      </span>
                      <span className="pl-4pxr pr-8pxr py-4pxr mr-8pxr flex items-center rounded-[4px] text-ef-neutral-white ef-body-sm bg-ef-state-cyan-500 ">
                        <Movement className="stroke-ef-neutral-white mr-4pxr" /> {t("common.internal_movement")}
                      </span>
                      <span className="pl-4pxr pr-8pxr py-4pxr mr-8pxr flex items-center rounded-[4px] text-ef-neutral-white ef-body-sm bg-ef-neutral-500 ">
                        <Unknown className="stroke-ef-neutral-white mr-4pxr" /> {t("common.unknown")}
                      </span>
                    </div>
                  </div>
                </div>
                {/* 캘린더 제목 끝 */}

                {/* 캘린더 시작 */}
                <div className="mb-324pxr">
                  {/* 데이터 로딩중이면 */}
                  {isLoading ? (
                    <div className="relative justify-center w-full flex items-center h-948pxr">
                      <Lottie animationData={animationData} style={{ width: 200, height: 200 }} />
                    </div>
                  ) : (
                    <FullCalendar
                      key={selectedDate.toISOString()}
                      plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
                      ref={calendarRef}
                      initialView="dayGridMonth"
                      events={result}
                      headerToolbar={{
                        left: "",
                        center: "",
                        right: "",
                      }}
                      height={948}
                      datesSet={(arg) => {
                        //월 변경 시 디폴트 1일으로 설정
                        setSelectedDate(selectedDate ? selectedDate : arg.view.currentStart);
                      }}
                      showNonCurrentDates={false}
                      fixedWeekCount={false}
                      eventContent={eventContent}
                      eventClick={handleDateClick}
                      dateClick={handleDateClick}
                      initialDate={selectedDate ? selectedDate : new Date()}
                      dayMaxEventRows={5}
                      dayCellClassNames={handleCellClassNames}
                    />
                  )}
                  <span className="text-ef-neutral-600 ef-body-lg flex justify-end mt-24pxr">{t("manage.unit_heads")}</span>
                </div>
                {/* 캘린더 끝 */}
              </>
            )
          }
        </div>

        {/* 보고서 목록  */}
        <div className="w-[744px]  border border-l border-t-transparent border-ef-neutral-200 border-b-transparent border-r-transparent bg-ef-neutral-50">
          <div className="pt-40pxr px-32pxr h-full flex flex-col">
            <span className="ef-headline-md">{t("manage.report_list")}</span>
            <div className="flex justify-between mb-24pxr mt-26pxr">
              <span className="ef-title-md">
                {format(new Date(selectedDate), "yyyy.MM.dd")} <span className="ef-title-md text-ef-neutral-400">({filteredReportList && filteredReportList.length})</span>
              </span>
              <div className="">
                <BtnSort sortState={sortOrder === "asc"} setSortState={handleSortClick} />
              </div>
            </div>

            <div className="flex flex-col w-full h-full  overflow-x-hidden  overflow-y-auto">
              {isLoading ? (
                <span className="text-center ef-body-xxl text-ef-neutral-600 mt-100pxr">{t("manage.loading")}</span>
              ) : sortedReportList && sortedReportList.length === 0 ? (
                <span className="text-center ef-body-xxl text-ef-neutral-600 mt-100pxr">{t("manage.no_report_list")}</span>
              ) : (
                sortedReportList &&
                sortedReportList.map((v, index) => {
                  return (
                    <div key={index}>
                      <div
                        style={{ backgroundColor: isReportCreated && sortedReportList[0].id === v.id ? "#F5FAFF" : "" }}
                        className={`border border-solid ${
                          v.id === selectedId ? `!border-ef-primary-500` : `!border-ef-neutral-150`
                        }  ef-card-movementList h-180pxr relative flex justify-start mb-16pxr hover:!border-ef-primary-500
                        `}
                        onClick={() => handleReportClick(v)}
                      >
                        {/* 썸네일 */}
                        <div className="mr-17pxr flex">
                          <div className="w-192pxr flex flex-col items-center overflow-hidden">
                            <img src={v.image_path ? v.image_path : Illust} alt="writeReport" className="object-cover h-full" onError={(e: any) => onErrorHandler(e, v.id)} />
                            {thumbnailErrors.includes(v.id) && <span className="text-ef-neutral-900 ef-label-sm">{t("manage.generation_image")}</span>}
                          </div>
                          {/* {v?.image_path ? (
                            <div className="w-192pxr h-144pxr flex flex-col items-center overflow-hidden">
                              <img src={v.image_path} alt="writeReport" className="object-cover h-full" onError={(e: any) => onErrorHandler(e, v.id)} />
                              {thumbnailErrors.includes(v.id) && <span className="text-ef-neutral-900 ef-label-sm">{t("report.generation_image")}</span>}
                            </div>
                          ) : (
                            <div
                              className="bg-ef-neutral-white border border-solid items-center"
                              style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}
                            >
                              <img src={WriteReport} alt="writeReport" />
                              <span className="ef-label-sm absolute text-ef-neutral-900" style={{ position: "absolute" }}>
                                {t("report.handwritten_report_comment")}
                              </span>
                            </div>
                          )} */}
                        </div>
                        {/* 썸네일 끝 */}

                        {/* 설명 */}
                        <div className="flex flex-col items-start">
                          <div className="w-full flex justify-between">
                            <span>{renderWorkStatus(v.category?.id, v.stock)}</span>
                            {v.age ? <span className="flex flex-row mb-16pxr">{RatingDisplay(v.rating, 24)}</span> : ""}
                          </div>
                          <span className="flex justify-between mb-8pxr">
                            <span className="ef-body-sm mr-8pxr  w-108pxr max-w-[108px] text-neutral-700">{t("common.day_age")}</span>
                            <span className="ef-body-md text-neutral-900">{v?.age ? v?.age + t("common.aged") : t("common.no_info")}</span>
                          </span>
                          <span className="flex justify-between mb-8pxr">
                            <span title={t("manage.camera_information")} className="ef-body-sm mr-8pxr w-[108px] max-w-[108px] text-neutral-700 line-clamp-1 break-all">
                              {t("manage.camera_information")}
                            </span>
                            <span className="ef-body-md text-neutral-900 flex flex-row">{v.camera === null ? t("common.no_info") : v.camera.name}</span>
                          </span>
                          <span className="flex justify-between mb-8pxr">
                            <span className="ef-body-sm mr-8pxr w-[108px] max-w-[108px] text-neutral-700">{t("common.work_time")}</span>
                            <span className="ef-body-md text-neutral-900 w-314pxr">
                              {getTimeLocale(new Date(v.started_at!))} ~ {getTimeLocale(new Date(v.ended_at))}
                              {" " + calculateDuration(new Date(v.started_at!), new Date(v.ended_at))}
                            </span>
                          </span>
                          <span className="flex justify-between">
                            <span className="ef-body-sm mr-8pxr  w-108pxr max-w-[108px] text-neutral-700">{t("common.weight")}</span>
                            <span className="ef-body-md text-neutral-900">
                              <span>{v.avg_weight ? v.avg_weight.toFixed(2) + "kg" : <span className="text-ef-neutral-300">{t("manage.weight_not_entered")}</span>}</span>
                              <span>{v.avg_weight ? ` (${t("common.total")} ${(v.avg_weight * v.stock).toFixed(2)})kg` : ""}</span>
                            </span>
                          </span>
                        </div>
                        {/* 설명 끝 */}

                        {/* 여백 */}
                        <div></div>
                        {/* 미확인 보고서 */}
                        {/* {!v.confirm ? (
                          <div className="absolute p-0pxr m-0pxr right-0pxr top-0pxr rounded-[8px] w-670pxr h-180pxr bg-ef-neutral-900 bg-opacity-[0.8] flex flex-col items-center justify-center">
                            <Danger className=" fill-ef-neutral-white" />
                            <div className="flex flex-row mt-12pxr">
                              <span className="text-ef-neutral-white ef-title-md">{t("report.please_check_unconfirmed_report")}</span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )} */}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
        {/* 보고서 목록 끝 */}
      </div>
    </>
  );
}
