import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { ReactComponent as ArrowLeft } from "assets/icons/general/ef-general-arrowLeft.svg";
import { ReactComponent as ArrowRight } from "assets/icons/general/ef-general-arrowRight.svg";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { format, isSameHour } from "date-fns";
import noData from "assets/images/report/no_report_data.png";
import { roomsType } from "query/piggery/type";
import { useGrowCamDetail } from "query/efg";
import { useEfcCamDetail } from "query/efc";
import { datePickerLocale } from "utils/useDatepicker";
import DropDownFilter from "components/common/dropdown/DropDownFilter";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  efcId: number;
};
type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

function EfcModal(props: Props) {
  const { efcId, isToggle, setIsToggle } = props;
  const { t } = useTranslation();
  const { data } = useEfcCamDetail(efcId);
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [thumbnailErrors, setThumbnailErrors] = useState<number[]>([]);

  const timeOptions = data?.time_images
    .map((image) => {
      const hour = new Date(image.created_at).getHours();
      const name = `${hour.toString().padStart(2, "0")}:00 ~ ${hour.toString().padStart(2, "0")}:59`;
      return { value: hour, name };
    })
    .sort((a, b) => a.value - b.value);

  const maxTime = data?.time_images.reduce(
    (latest, curr) => {
      const currTime = new Date(curr.created_at);
      const latestTime = new Date(latest.created_at);
      return currTime > latestTime ? curr : latest;
    },
    data?.time_images[0],
  );

  const minTime = data?.time_images.reduce(
    (latest, curr) => {
      const currTime = new Date(curr.created_at);
      const latestTime = new Date(latest.created_at);
      return currTime < latestTime ? curr : latest;
    },
    data?.time_images[0],
  );

  // 썸네일 이미지 표출 오류 핸들러
  const onErrorHandler = (e: any, id: number) => {
    setThumbnailErrors((prevErrors) => [...prevErrors, id]);
    e.target.src = require(`assets/images/report/generate_img.png`);
  };

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    setIsToggle(false);
    setSelectedTime(new Date(format(new Date(), "yyyy-MM-dd HH:mm:00")));
  };

  // 이전 시간으로 이동하는 버튼 핸들러
  const handlePrevClick = () => {
    if (selectedTime?.getHours() === 0) return; // 00시일 경우 비활성화 처리

    if (selectedTime) {
      let prevTime = new Date(selectedTime);
      let foundImage = false; // 데이터를 찾았는지 여부를 추적하는 변수

      // 선택된 시간 이전에 가장 가까운 시간대 탐색
      for (let i = selectedTime.getHours() - 1; i >= 0; i--) {
        prevTime.setHours(i);
        const prevImage = data?.time_images.find((image) => new Date(image.created_at).getHours() === prevTime.getHours());

        if (prevImage) {
          setSelectedTime(prevTime); // 가장 가까운 이전 시간으로 설정
          setSelectedImage(prevImage.image_path); // 해당 시간의 이미지 설정
          foundImage = true; // 이미지를 찾았음을 표시
          break; // 가장 가까운 이전 이미지를 찾으면 루프 중지
        } else if (i === selectedTime.getHours() - 1) {
          // 바로 다음 시간에 데이터가 없으면 noData를 설정
          setSelectedTime(prevTime);
          setSelectedImage("noData"); // 이미지가 없을 경우 noData 설정
          foundImage = true;
          break; // 바로 다음 시간에서 noData 설정 후 중지
        }
      }

      if (!foundImage) {
        // 이전 시간에 데이터가 없으면 noData 설정
        // const noDataTime = new Date(selectedTime);
        // noDataTime.setHours(selectedTime.getHours() - 1);
        // setSelectedTime(noDataTime);
        // setSelectedImage("noData");
      }
    }
  };

  // 다음 시간으로 이동하는 버튼 핸들러
  const handleNextClick = () => {
    if (selectedTime?.getHours() === 23) return; // 23시일 경우 비활성화 처리

    if (selectedTime) {
      let nextTime = new Date(selectedTime);

      // 선택된 시간 이후에 가장 가까운 시간을 찾음
      let foundImage = false;

      for (let i = selectedTime.getHours() + 1; i <= 23; i++) {
        nextTime.setHours(i);
        const nextImage = data?.time_images.find((image) => new Date(image.created_at).getHours() === nextTime.getHours());

        if (nextImage) {
          // 데이터가 있으면 해당 시간과 이미지 설정
          setSelectedTime(nextTime);
          setSelectedImage(nextImage.image_path);
          foundImage = true;
          break; // 가장 가까운 이미지를 찾으면 루프 중지
        } else if (i === selectedTime.getHours() + 1) {
          // 바로 다음 시간에 데이터가 없으면 noData를 설정
          setSelectedTime(nextTime);
          setSelectedImage("noData"); // 이미지가 없을 경우 noData 설정
          foundImage = true;
          break; // 바로 다음 시간에서 noData 설정 후 중지
        }
      }

      if (!foundImage) {
        // 마지막까지 이미지가 없으면 noData를 유지
        // setSelectedImage("noData");
      }
    }
  };

  // const isMaxTime = selectedTime >= maxTime;

  const handleTimePickerChange = (time: Date) => {
    if (time) {
      setSelectedTime(time);
    }
  };

  const getExactImageForSelectedTime = () => {
    if (!data?.time_images || data.time_images.length === 0) return null;

    const exactImage = data.time_images.find((image) => new Date(image.created_at).getHours() === selectedTime?.getHours());
    console.log(exactImage);
    return exactImage?.image_path || noData;
  };

  useEffect(() => {
    const newImage = getExactImageForSelectedTime();

    if (newImage) {
      setSelectedImage(newImage);
    }
  }, [selectedTime, data]);

  useEffect(() => {
    if (maxTime?.created_at) {
      setSelectedTime(new Date(maxTime?.created_at));
    }
  }, [data]);

  // 시간 변경 핸들러 추가
  const handleTimeChange = (value: number) => {
    const newSelectedTime = new Date();
    newSelectedTime.setHours(value, 0, 0, 0);
    setSelectedTime(newSelectedTime);
  };

  return (
    <>
      <Modal
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "#303030", // neutral-900
            opacity: "0.3 !important",
          },
        }}
        open={isToggle}
        onClose={(_, reason) => onClose(reason)}
        className="flex justify-center items-center pt-120pxr overflow-y-auto"
      >
        <div className="w-540pxr bg-ef-neutral-white outline-none flex flex-col p-40pxr">
          {/* header */}
          <div className="flex relative w-full">
            <span className="flex flex-row mx-auto text-ef-neutral-900 ef-title-lg">{data?.name + " " + t("common.details")}</span>
            <button className="absolute right-0pxr" onClick={() => onClose("closeButtonClick")}>
              <GeneralClose className="stroke-ef-neutral-900" />
            </button>
          </div>

          <div className="flex items-center justify-center mt-60pxr mb-40pxr">
            <button
              className={`border border-solid p-16pxr h-56pxr rounded-md mr-8pxr ${
                data?.time_images.length === 0 || selectedTime?.getHours() === 0 || selectedTime?.getHours() === new Date(minTime?.created_at!).getHours() ? "bg-ef-neutral-50 cursor-default" : ""
              }`}
              onClick={handlePrevClick}
              disabled={data?.time_images.length === 0 || selectedTime?.getHours() === 0 || selectedTime?.getHours() === new Date(minTime?.created_at!).getHours()}
            >
              <ArrowLeft className={`stroke-ef-neutral-${data?.time_images.length === 0 || selectedTime?.getHours() === 0 ? "500" : "900"}`} />
            </button>

            <div className="flex items-center justify-center h-56pxr">
              <DropDownFilter title={""} state={selectedTime?.getHours()} setState={handleTimeChange} options={timeOptions!} />
            </div>

            <button
              disabled={data?.time_images.length === 0 || selectedTime?.getHours() === 23 || selectedTime?.getHours() === new Date(maxTime?.created_at!).getHours()}
              className={`border border-solid p-16pxr h-56pxr rounded-md ml-8pxr ${
                data?.time_images.length === 0 || selectedTime?.getHours() === 23 || selectedTime?.getHours() === new Date(maxTime?.created_at!).getHours() ? "bg-ef-neutral-50 cursor-default" : ""
              }`}
              onClick={handleNextClick}
            >
              <ArrowRight className={`stroke-ef-neutral-${data?.time_images.length === 0 || selectedTime?.getHours() === 23 ? "500" : "900"}`} />
            </button>
          </div>

          <div className="flex flex-col justify-center items-center w-full">
            <img className="w-344pxr flex items-center justify-center mb-48pxr" onError={(e: any) => onErrorHandler(e, efcId)} src={selectedImage ? selectedImage : noData} alt="efc_thumbnail" />{" "}
            <div className="flex flex-col w-full gap-24pxr">
              <div className="flex w-full justify-between items-center">
                <span className="ef-label-md w-77pxr max-w-[77px] text-ellipsis overflow-hidden">{t("status.name_of_location")}</span>
                <div
                  className={
                    "group rounded-[8px] border border-solid border-ef-neutral-150 w-340pxr h-40pxr py-8pxr px-16pxr flex flex-row focus-within:border-ef-primary-500 stroke-ef-neutral-700 " +
                    "bg-ef-neutral-50 text-ef-neutral-500"
                  }
                >
                  <input disabled className="mr-8pxr w-0pxr grow focus:outline-none caret-ef-primary-900 ef-body-lg placeholder-ef-neutral-300 disabled:bg-ef-neutral-50" value={data?.room.name} />
                </div>
              </div>
              <div className="flex w-full justify-between items-center">
                <span className="ef-label-md w-77pxr max-w-[77px] text-ellipsis overflow-hidden">{t("common.device_type")}</span>
                <div
                  className={
                    "group rounded-[8px] border border-solid border-ef-neutral-150 w-340pxr h-40pxr py-8pxr px-16pxr flex flex-row focus-within:border-ef-primary-500 stroke-ef-neutral-700 " +
                    "bg-ef-neutral-50 text-ef-neutral-500"
                  }
                >
                  <input
                    disabled
                    className="mr-8pxr w-0pxr grow focus:outline-none caret-ef-primary-900 ef-body-lg placeholder-ef-neutral-300 disabled:bg-ef-neutral-50"
                    value={`EFC ${t("manage.camera")}`}
                  />
                </div>
              </div>
              <div className="flex w-full justify-between items-center">
                <span className="ef-label-md w-77pxr max-w-[77px] text-ellipsis overflow-hidden">{t("status.device_name")}</span>
                <div
                  className={
                    "group rounded-[8px] border border-solid border-ef-neutral-150 w-340pxr h-40pxr py-8pxr px-16pxr flex flex-row focus-within:border-ef-primary-500 stroke-ef-neutral-700 " +
                    "bg-ef-neutral-50 text-ef-neutral-500"
                  }
                >
                  <input disabled className="mr-8pxr w-0pxr grow focus:outline-none caret-ef-primary-900 ef-body-lg placeholder-ef-neutral-300 disabled:bg-ef-neutral-50" value={data?.name} />
                </div>
              </div>
              <div className="flex w-full justify-between items-center">
                <span className="ef-label-md w-77pxr max-w-[77px] text-ellipsis overflow-hidden">{t("common.device_connection_status")}</span>
                <div
                  className={
                    "group rounded-[8px] border border-solid border-ef-neutral-150 w-340pxr h-40pxr py-8pxr px-16pxr flex flex-row focus-within:border-ef-primary-500 stroke-ef-neutral-700 " +
                    "bg-ef-neutral-50 text-ef-neutral-500"
                  }
                >
                  <input
                    disabled
                    className="mr-8pxr w-0pxr grow focus:outline-none caret-ef-primary-900 ef-body-lg placeholder-ef-neutral-300 disabled:bg-ef-neutral-50"
                    value={data?.connected === true ? t("common.normal") : t("common.abnormal")}
                  />
                </div>
              </div>

              <div className="flex w-full justify-between items-center">
                <span className="ef-label-md w-77pxr max-w-[77px] text-ellipsis overflow-hidden">{t("status.device_connection_datetime")}</span>
                <div
                  className={
                    "group rounded-[8px] border border-solid border-ef-neutral-150 w-340pxr h-40pxr py-8pxr px-16pxr flex flex-row focus-within:border-ef-primary-500 stroke-ef-neutral-700 " +
                    "bg-ef-neutral-50 text-ef-neutral-500"
                  }
                >
                  <input
                    disabled
                    className="mr-8pxr w-0pxr grow focus:outline-none caret-ef-primary-900 ef-body-lg placeholder-ef-neutral-300 disabled:bg-ef-neutral-50"
                    value={data?.last_access_at ? format(new Date(data?.last_access_at), "yyyy-MM-dd HH:mm") : t("common.no_info")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EfcModal;
