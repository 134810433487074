import React, { useEffect, useRef, useState } from "react";
import { Modal } from "@mui/material";
import { ReactComponent as Close } from "assets/icons/general/ef-general-close.svg";
import { ReactComponent as File } from "assets/icons/general/ef-general-paperclip.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useReleasePageNum } from "query/notice";
import { NoticePopup } from "query/notice/type";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Props = {
  data: NoticePopup;
};

function NoticeModal(props: Props) {
  const { data } = props;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [isToggle, setIsToggle] = useState(true);
  const [isEndOfScroll, setIsEndOfScroll] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const containerHeight = containerRef.current?.scrollHeight;
  const { data: pageData } = useReleasePageNum(data.id);

  const getLocalizedField = (field: 'title' | 'content') => {
    const lang = i18n.language;
    const fallbackLang = 'ko';
    
    const fieldKey = `${field}_${lang}` as keyof typeof data;
    const fallbackKey = `${field}_${fallbackLang}` as keyof typeof data;
    
    return String(data[fieldKey] || data[fallbackKey] || '');
  };

  const onClose = (value: CloseReason) => {
    if (value === "backdropClick") return;
    else setIsToggle(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (container) {
        const isEnd = container.scrollTop + container.clientHeight >= container.scrollHeight;
        setIsEndOfScroll(isEnd);
      }
    };
    containerRef.current?.addEventListener("scroll", handleScroll);

    return () => {
      containerRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef.current]);

  const handleClose = () => {
    sessionStorage.setItem("popupId", data.id.toString());
  };

  const handleEnoughtToday = () => {
    document.cookie = `popupId=${data.id}; path=/; expires=${new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59).toUTCString()};`;
    setIsToggle(false);
  };

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="absolute h-full flex justify-center outline-none pt-120pxr"
    >
      <div className="w-328pxr h-fit bg-ef-neutral-white outline-none flex flex-col relative">
        <div className="px-16pxr py-24pxr flex flex-row gap-8pxr bg-[linear-gradient(270deg,_#033687_0%,_#1B5ABF_100%)]">
          <div className="ml-32pxr flex flex-row gap-4pxr items-center justify-center w-full">
            {data.file !== null && <File className="stroke-ef-neutral-white" />}
            <span className="ef-title-md text-ef-neutral-white line-clamp-1 break-all">
              {getLocalizedField('title')}
            </span>
          </div>
          <button
            onClick={() => {
              handleClose();
              setIsToggle(false);
            }}
            type="button"
            className="outline-none"
          >
            <Close className="stroke-ef-neutral-white" />
          </button>
        </div>
        <div
          className={
            "px-16pxr py-24pxr relative before:content-[''] before:bottom-0pxr before:left-0pxr before:h-160pxr before:w-full before:z-[10] before:pointer-events-none before:bg-fixed before:absolute " +
            (isEndOfScroll || (typeof containerHeight !== "undefined" && containerHeight <= 360)
              ? "before:bg-transparent"
              : "before:bg-[linear-gradient(180deg,_transparent_60%,_#FFFFFF_80%)] tab:before:bg-[linear-gradient(180deg,_transparent_45%,_#FFFFFF_60%)]")
          }
        >
          <div ref={containerRef} className="overflow-y-auto w-full max-h-360pxr ef-body-lg ck-content" dangerouslySetInnerHTML={{ __html: getLocalizedField('content') as string }}></div>
        </div>
        <div className="w-full flex flex-row border-t border-solid border-ef-neutral-150 ef-label-md">
          <button onClick={handleEnoughtToday} type="button" className="w-1/2 flex justify-center py-14pxr border-r border-solid border-ef-neutral-150">
            {t("notice.enough_for_today")}
          </button>
          <button
            onClick={() => {
              handleClose();
              navigate("/notice", {
                state: {
                  pageNum: pageData ? pageData.page_number : 1,
                  noticeId: pageData ? pageData.notice_id : 0,
                },
              });
            }}
            type="button"
            className="w-1/2 flex justify-center py-14pxr bg-ef-primary-500 text-ef-neutral-white"
          >
            {t("notice.view_more")}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default NoticeModal;
