import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { ReactComponent as ArrowDown } from "assets/icons/general/ef-general-arrowDown.svg";
import { ReactComponent as Calendar } from "assets/icons/report/ef-report-calendar.svg";
import { useTranslation } from "react-i18next";
import { PiggeryForFarmSummary } from "query/farm/type";
import DatePicker from "react-datepicker";
import { format, isBefore, subHours } from "date-fns";
import { useRoomEnvDetail, useRoomMonitoring } from "query/rooms";
import noData from "assets/images/report/no_report_data.png";
import ExpandImgModal from "../../../1_farmStatus/modal/stock/expandImgModal";
import { RoomMonitoring } from "query/rooms/type";
import { datePickerLocale } from "utils/useDatepicker";
import DropDownFilter from "components/common/dropdown/DropDownFilter";
import { ReactComponent as ArrowLeft } from "assets/icons/general/ef-general-arrowLeft.svg";
import { ReactComponent as ArrowRight } from "assets/icons/general/ef-general-arrowRight.svg";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  roomId: number;
  selectedDate: string;
};

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

function MonitoringModal(props: Props) {
  const { isToggle, setIsToggle, roomId, selectedDate } = props;
  const { data } = useRoomEnvDetail(roomId);
  const { t } = useTranslation();
  const [selectedTime, setSelectedTime] = useState<Date>(new Date(new Date().setHours(0, 0, 0, 0)));
  const [expandImg, setExpandImg] = useState(false);
  const [historyInfo, setHistoryInfo] = useState<RoomMonitoring>();
  const [isError, setIsError] = useState(false);
  const { data: monitoringData, isLoading } = useRoomMonitoring(roomId, { base_datetime: selectedDate + "T" + format(selectedTime, "HH:00:00") });

  const timeOptions = Array.from({ length: 24 }, (_, value) => {
    const name = `${value.toString().padStart(2, "0")}:00 ~ ${value.toString().padStart(2, "0")}:59`;
    return { value, name };
  });

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    setIsToggle(false);
    setSelectedTime(new Date(new Date().setHours(0, 0, 0, 0)));
  };

  useEffect(() => {
    setSelectedTime(new Date(new Date().setHours(0, 0, 0, 0)));
  }, [selectedDate]);

  const handlePrevClick = () => {
    if (selectedTime && selectedTime.getHours() > 0) {
      const newTime = new Date(selectedTime);
      newTime.setHours(selectedTime.getHours() - 1);
      setSelectedTime(newTime);
    }
  };

  const handleNextClick = () => {
    if (selectedTime && selectedTime.getHours() < 23) {
      const newTime = new Date(selectedTime);
      newTime.setHours(selectedTime.getHours() + 1);
      setSelectedTime(newTime);
    }
  };

  return (
    <>
      <ExpandImgModal distinct="room" isToggle={expandImg} setIsToggle={setExpandImg} historyInfo={historyInfo} />

      <Modal
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "#303030", // neutral-900
            opacity: "0.3 !important",
          },
        }}
        open={isToggle}
        onClose={(_, reason) => onClose(reason)}
        className="flex justify-center py-80pxr"
      >
        <div className="w-995pxr h-852pxr bg-ef-neutral-white outline-none flex flex-col py-40pxr">
          {/* header */}
          <div className="flex relative px-40pxr">
            <span className="flex mx-auto text-ef-neutral-900 ef-title-lg">
              {selectedDate !== "" ? `${format(new Date(selectedDate), "MMM do", { locale: datePickerLocale() })} - ${data?.room.name} ${t("manage.camera")}` : ""}
            </span>
            <button className="absolute right-40pxr" onClick={() => onClose("closeButtonClick")}>
              <GeneralClose className="stroke-ef-neutral-900" />
            </button>
          </div>
          {/* 필터 */}
          <div className="flex flex-col flex-wrap ef-label-md text-ef-neutral-900 mt-60pxr">
            <div className="flex flex-row gap-16pxr items-center justify-center">
              <div className="flex items-center justify-center">
                <button
                  className={`border border-solid p-16pxr h-56pxr rounded-md mr-8pxr ${
                    selectedTime?.getHours() === 0 ? "bg-ef-neutral-50 cursor-default" : ""
                  }`}
                  onClick={handlePrevClick}
                  disabled={selectedTime?.getHours() === 0}
                >
                  <ArrowLeft className={`stroke-ef-neutral-${selectedTime?.getHours() === 0 ? "500" : "900"}`} />
                </button>

                <div className="flex items-center justify-center h-56pxr">
                  <DropDownFilter 
                    title={""} 
                    state={selectedTime?.getHours()} 
                    setState={(value) => {
                      const newTime = new Date(selectedTime || new Date());
                      newTime.setHours(value);
                      setSelectedTime(newTime);
                    }} 
                    options={timeOptions} 
                  />
                </div>

                <button
                  disabled={selectedTime?.getHours() === 23}
                  className={`border border-solid p-16pxr h-56pxr rounded-md ml-8pxr ${
                    selectedTime?.getHours() === 23 ? "bg-ef-neutral-50 cursor-default" : ""
                  }`}
                  onClick={handleNextClick}
                >
                  <ArrowRight className={`stroke-ef-neutral-${selectedTime?.getHours() === 23 ? "500" : "900"}`} />
                </button>
              </div>
            </div>
          </div>
          {/* 필터 끝 */}
          <main className="overflow-x-hidden overflow-y-auto px-40pxr mt-24pxr">
            {monitoringData && monitoringData?.length > 0 ? (
              <div className="grid grid-cols-3 gap-16pxr">
                {monitoringData?.map((v, index) => (
                  <div key={`room-${index}`} className="relative rounded-[8px] border border-solid border-ef-neutral-150 w-295pxr">
                    {v.history[0]?.image_path ? (
                      isError ? (
                        <div className="flex flex-col items-center h-180pxr w-full bg-ef-neutral-50 rounded-t-lg py-20pxr">
                          <img src={noData} alt="no_data" className="w-140pxr h-100pxr" />
                          <span className="ef-body-sm text-ef-neutral-700">{t("common.image_path_not_found")}</span>
                        </div>
                      ) : (
                        <img
                          onError={() => setIsError(true)}
                          onClick={() => {
                            setExpandImg(true);
                            setHistoryInfo(v);
                          }}
                          src={isError ? noData : v.history[0]?.image_path}
                          alt="thumbnail"
                          className="cursor-pointer h-180pxr w-full bg-ef-neutral-150 rounded-t-lg"
                        />
                      )
                    ) : (
                      <div className="flex flex-col items-center h-180pxr w-full bg-ef-neutral-50 rounded-t-lg py-20pxr">
                        <img src={noData} alt="no_data" className="w-140pxr h-100pxr" />
                        <span className="ef-body-sm text-ef-neutral-700">{t("common.no_data")}</span>
                      </div>
                    )}
                    <div className="h-full rounded-b-lg flex flex-col gap-8pxr px-24pxr py-16pxr">
                      <div className="flex flex-row justify-between">
                        <span className="ef-body-xl text-ef-neutral-900">{v?.name}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : isLoading ? (
              <div className="flex flex-col w-full items-center my-80pxr">
                <img src={noData} alt="noData" className="flex w-310pxr h-220pxr " />
                <span className="text-center ef-body-lg text-ef-neutral-600">{t("common.data_loading")}</span>
              </div>
            ) : (
              <div className="flex flex-col w-full items-center my-80pxr">
                <img src={noData} alt="noData" className="flex w-310pxr h-220pxr " />
                <span className="text-center ef-body-lg text-ef-neutral-600">{t("status.no_grow_comment")}</span>
                <div className="flex flex-row mt-24pxr">
                  <button
                    className="ef-btn-r8-sm !ef-label-md flex flex-row gap-8pxr"
                    onClick={() => {
                      setIsToggle(false);
                      // setAddHerdModal(true);
                      window.open("https://intflow.ai/contact/", "_blank");
                    }}
                  >
                    {t("status.contact")}
                  </button>
                </div>
              </div>
            )}{" "}
            {/* <div className="rounded-[8px] border border-solid border-ef-neutral-150 w-295pxr h-315pxr">
                <div className="flex flex-col items-center h-180pxr w-full bg-ef-neutral-50 rounded-t-lg py-20pxr">
                  <img src={noData} alt="no_data" className="w-140pxr h-100pxr" />
                  <span className="ef-body-sm text-ef-neutral-700">네트워크 연결에 이상이 생겨</span>
                  <span className="ef-body-sm text-ef-neutral-700">데이터가 저장되지 않았습니다.</span>
                </div>
                <div className="h-full rounded-b-lg flex flex-col gap-8pxr px-24pxr py-16pxr">
                  <div className="flex flex-row items-center gap-8pxr">
                    <span className="ef-body-xl text-ef-neutral-900">돈방이름</span>
                    <span className="w-1pxr h-10pxr bg-ef-neutral-200 mx-8pxr"></span>
                    <span className="ef-body-xl text-ef-neutral-900">카메라 이름</span>
                  </div>
                  <div className="w-full h-1pxr bg-ef-neutral-150"></div>
                  <div className="w-126pxr">
                    <span className="ef-body-lg">네트워크 연결 상태</span>
                    <span></span>
                  </div>
                  <div className="w-126pxr">
                    <span className="ef-body-lg">카메라 오염 상태</span>
                    <span></span>
                  </div>
                </div>
              </div>
              <div className="rounded-[8px] border border-solid border-ef-neutral-150 w-295pxr h-315pxr">
                <div className="flex flex-col items-center h-180pxr w-full bg-ef-neutral-50 rounded-t-lg py-20pxr">
                  <img src={noData} alt="no_data" className="w-140pxr h-100pxr" />
                  <span className="ef-body-sm text-ef-neutral-700">저장 기간이 만료되었습니다.</span>
                  <span className="ef-body-sm text-ef-neutral-700">(저장 기간 : 이미지 3년, 동영상 5년)</span>
                </div>
                <div className="h-full rounded-b-lg flex flex-col gap-8pxr px-24pxr py-16pxr">
                  <div className="flex flex-row items-center gap-8pxr">
                    <span className="ef-body-xl text-ef-neutral-900">돈방이름</span>
                    <span className="w-1pxr h-10pxr bg-ef-neutral-200 mx-8pxr"></span>
                    <span className="ef-body-xl text-ef-neutral-900">카메라 이름</span>
                  </div>
                  <div className="w-full h-1pxr bg-ef-neutral-150"></div>
                  <div className="w-126pxr">
                    <span className="ef-body-lg">네트워크 연결 상태</span>
                    <span></span>
                  </div>
                  <div className="w-126pxr">
                    <span className="ef-body-lg">카메라 오염 상태</span>
                    <span></span>
                  </div>
                </div>
              </div> */}
          </main>
        </div>
      </Modal>
    </>
  );
}

export default MonitoringModal;
