import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as Calendar } from "assets/icons/general/ef-general-calendar.svg";
import { ReactComponent as ArrowDown } from "assets/icons/general/ef-general-arrowDown.svg";
import "styles/datepicker.css";
import { datePickerLocale } from "utils/useDatepicker";
import { useTranslation } from "react-i18next";
import { differenceInCalendarDays } from "date-fns";

type Props = {
  date: Date | null;
  setDate: React.Dispatch<React.SetStateAction<Date | null>>;
  disabled?: boolean;
  dateFormat?: string;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
};

function WorkDatetimePicker(props: Props) {
  const { disabled = false, date, setDate, dateFormat = "yyyy-MM-dd", placeholder, maxDate, minDate } = props;
  const { t } = useTranslation();
  const dateRef = useRef<HTMLDivElement>(null);
  const timeRef = useRef<HTMLDivElement>(null);
  const [openState, setOpenState] = useState(false);
  const [timeOpenState, setTimeOpenState] = useState(false);

  useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (dateRef.current && !dateRef.current.contains(e.target as Node)) {
        setOpenState(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dateRef]);

  useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (timeRef.current && !timeRef.current.contains(e.target as Node)) {
        setTimeOpenState(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [timeRef]);

  return (
    <div className="flex flex-row gap-8pxr">
      <div className="w-248pxr">
        <div
          ref={dateRef}
          className={
            "group rounded-[8px] border border-solid border-ef-state-red-500 bg-ef-state-red-50 w-full h-40pxr py-8pxr px-16pxr flex flex-row items-center  " +
            (disabled ? "bg-ef-neutral-50 text-ef-neutral-500" : "text-ef-neutral-900 bg-ef-neutral-white cursor-pointer")
          }
        >
          <DatePicker
            id="singleDate"
            open={openState}
            disabled={disabled}
            autoComplete="off"
            onInputClick={() => setOpenState((prev) => !prev)}
            locale={datePickerLocale()}
            minDate={minDate}
            maxDate={maxDate}
            selected={date}
            onChange={(date) => {
              setDate(date);
              setOpenState(false);
            }}
            className="outline-none caret-transparent"
            dateFormat={dateFormat}
            onKeyDown={(e: any) => {
              if (e.keyCode === 229) e.preventDefault();
              e.preventDefault();
            }}
            placeholderText={placeholder}
          />
          {disabled ? <></> : <Calendar onClick={() => setOpenState((prev) => !prev)} className="stroke-ef-neutral-700 fill-ef-neutral-700" />}
        </div>
      </div>
      <div className="w-144pxr">
        <div
          ref={timeRef}
          className={
            "group rounded-[8px] border border-solid border-ef-state-red-500 bg-ef-state-red-50 w-full h-40pxr py-8pxr px-16pxr flex flex-row items-center " +
            (disabled ? "bg-ef-neutral-50 text-ef-neutral-500" : "text-ef-neutral-900 bg-ef-neutral-white cursor-pointer")
          }
        >
          <DatePicker
            id="singleDate"
            open={timeOpenState}
            disabled={disabled}
            autoComplete="off"
            onInputClick={() => setTimeOpenState((prev) => !prev)}
            locale={datePickerLocale()}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption={t("common.time")}
            minDate={minDate}
            maxDate={maxDate}
            selected={date}
            onChange={(date) => {
              setDate(date);
              setTimeOpenState(false);
            }}
            className="outline-none caret-transparent"
            dateFormat="HH:mm"
            onKeyDown={(e: any) => {
              if (e.keyCode === 229) e.preventDefault();
              e.preventDefault();
            }}
            placeholderText={placeholder}
          />
          {!disabled && (
            <ArrowDown onClick={() => setTimeOpenState((prev) => !prev)} className="min-w-[24px] stroke-ef-neutral-700" />
          )}
        </div>
      </div>
    </div>
  );
}

export default WorkDatetimePicker;
