import React, { useMemo, useState } from "react";
import DropDownFilter from "components/common/dropdown/DropDownFilter";
import { ReactComponent as Add } from "assets/icons/general/ef-general-add.svg";
import { ReactComponent as Sort } from "assets/icons/icon16_general/ef-16general-sort.svg";
import { ReactComponent as SortAsc } from "assets/icons/icon16_general/ef-16general-sortAscending.svg";
import { ReactComponent as SortDesc } from "assets/icons/icon16_general/ef-16general-sortDescending.svg";
import Pagination from "components/common/pagination/Pagination";
import { usePiggeryStructure } from "query/piggery";
import AddGroupModal from "components/pages/2_manage/stock/group/AddGroupModal";
import DropDownCheckBox from "components/common/dropdown/DropDownCheckBox";
import GroupDetail from "components/pages/2_manage/stock/group/GroupDetail";
import { useHerdList } from "query/herd";
import { differenceInDays, format } from "date-fns";
import { useTranslation } from "react-i18next";
import { ReactComponent as Refresh } from "assets/icons/general/ef-general-refresh.svg";
import noData from "assets/images/report/no_report_data.png";
import GroupRangeDatePicker from "components/pages/2_manage/stock/group/GroupRangeDatePicker";

type SortDirection = "asc" | "desc" | null;
interface SortState {
  key: string;
  direction: SortDirection;
  type: "numeric" | "string" | "date";
}

function ManageGroup() {
  const today = new Date();
  const lastYear = today.getFullYear() - 1;
  const PAGELIMIT = 20;
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);

  // 오늘부터 1년전으로
  const [startDate, setStartDate] = useState<Date | null>(new Date(lastYear, today.getMonth(), today.getDate()));
  const [endDate, setEndDate] = useState<Date | null>(today);
  const [selectedRunningState, setSelectedRunningState] = useState<boolean[]>([]);
  const [selectedPlace, setSelectedPlace] = useState<number[]>([]);
  const [selectedDateState, setSelectedDateState] = useState("arrival");

  const [addModalState, setAddModalState] = useState(false);

  const [detailToggle, setDetailToggle] = useState(false);
  const [groupId, setGroupId] = useState(0);

  const [sortStates, setSortStates] = useState<SortState[]>([]);

  const { data: piggeryList } = usePiggeryStructure();
  const [hoveredWeight, setHoveredWeight] = useState(0);
  const [hoveredStock, setHoveredStock] = useState(0);

  const { data: groupList, isError } = useHerdList(format(startDate!, "yyyy-MM-dd"), format(endDate!, "yyyy-MM-dd"), selectedRunningState, selectedPlace);

  const sortHandlers: Record<string, (a: any, b: any) => number> = {
    // 숫자 정렬
    numeric: (a, b) => a - b,
    // 문자열 정렬
    string: (a, b) => a.localeCompare(b),
    // 날짜 정렬
    date: (a, b) => new Date(a).getTime() - new Date(b).getTime(),
  };

  // 돈군 정렬한 데이터를 돌려주는 usememo(현재는 출생일 순만 있음)
  const sortedData = useMemo(() => {
    if (sortStates.length === 0) return groupList;

    if (groupList) {
      return [...groupList].sort((a, b) => {
        for (const { key, direction, type } of sortStates) {
          const valueA = a[key];
          const valueB = b[key];
          // 키에 따른 정렬 로직 호출
          const compare = sortHandlers[type](valueA, valueB);
          if (compare !== 0) {
            return direction === "asc" ? compare : -compare;
          }
        }
        return 0; // 모든 조건에서 동등하면 순서 유지
      });
    }
  }, [groupList, sortStates]);

  // 필터 드롭다운용 돈사 목록
  const piggeryState = useMemo(() => {
    if (piggeryList)
      return piggeryList.map((o) => {
        return { value: o.id, name: o.name };
      });
    else return [];
  }, [piggeryList]);

  // 초기화 버튼 함수
  const handleReset = () => {
    setStartDate(new Date(lastYear, today.getMonth(), today.getDay()));
    setEndDate(today);
    setSelectedRunningState([]);
    setSelectedPlace([]);
    setSelectedDateState(dateState[0].value);
  };

  // 사육 필터용 배열
  const runningArr = [
    { value: true, name: t("common.breeding") },
    { value: false, name: t("common.closed") },
  ];

  const runningState = useMemo(() => {
    if (runningArr)
      return runningArr.map((o) => {
        return { value: o.value, name: o.name };
      });
    else return [];
  }, [piggeryList]);

  // 일자 필터링용
  const dateState = [
    { value: "arrival", name: t("common.arrival_date") },
    { value: "birth", name: t("common.birth_date") },
  ];

  // sorting 아이콘용
  const getSortIcon = (direction: SortDirection | undefined) => {
    switch (direction) {
      case "asc":
        return <SortAsc />;
      case "desc":
        return <SortDesc />;
      default:
        return <Sort />;
    }
  };

  // 키에 따른 정렬 함수
  const handleHeaderClick = (key: string, type: "numeric" | "string" | "date") => {
    setSortStates((prevStates) => {
      const existingState = prevStates.find((state) => state.key === key);

      if (existingState) {
        // 정렬 방향 토글
        const newDirection = existingState.direction === "asc" ? "desc" : existingState.direction === "desc" ? null : "asc";

        if (newDirection === null) {
          // 방향이 null이면 정렬 리스트에서 제거
          return prevStates.filter((state) => state.key !== key);
        }

        return prevStates.map((state) => (state.key === key ? { ...state, direction: newDirection } : state));
      }

      // 새로운 정렬 추가
      return [{ key, direction: "asc", type }, ...prevStates];
    });
  };

  return (
    <div className="w-full h-full flex flex-col">
      <AddGroupModal distinct="group" isToggle={addModalState} setIsToggle={setAddModalState} />
      <GroupDetail isToggle={detailToggle} setIsToggle={setDetailToggle} groupId={groupId} isEditable={sortedData?.find(group => group.id === groupId)?.active_state ?? false} />

      <div className="grow bg-ef-neutral-50">
        <div className="w-[1440px] mx-auto pt-60pxr pb-200pxr flex flex-col">
          <div className="flex flex-row items-start">
            <span className="ef-headline-lg text-ef-neutral-900 mr-4pxr">{t("gnb.herd_history")}</span>
            <span className="ef-headline-lg text-ef-neutral-400">{`(${groupList ? groupList.length : 0})`}</span>
            <button className="ml-16pxr ef-btn-r8-sm flex flex-row" onClick={() => setAddModalState(true)}>
              <Add className="mr-8pxr" />
              <span>{t("common.add_herd")}</span>
            </button>
          </div>
          <div className="mt-40pxr mb-32pxr flex flex-row flex-wrap gap-8pxr">
            <DropDownCheckBox title={t("common.breeding_status")} state={selectedRunningState} setState={setSelectedRunningState} options={runningState} />
            <DropDownCheckBox title={t("common.location")} state={selectedPlace} setState={setSelectedPlace} options={piggeryState} />
            <div className="w-276pxr items-center">
              <GroupRangeDatePicker startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
            </div>
            <button className="ef-btn-reset flex flex-row flex-wrap gap-4pxr items-center" onClick={() => handleReset()}>
              <Refresh />
              {t("common.reset")}
            </button>
          </div>
          <div className="w-full h-1pxr border-b border-solid border-ef-neutral-150"></div>
          {sortedData && sortedData.length !== 0 && !isError ? (
            <div className="flex flex-col w-full">
              <table className="ef-table-lg w-full mt-24pxr mb-60pxr stroke-ef-neutral-900">
                <thead>
                  <tr>
                    <th className="w-360pxr max-w-[360px]">{t("common.herd_name")}</th>
                    <th className="w-220pxr max-w-[220px]">{t("common.location")}</th>
                    <th className="w-159pxr max-w-[159px]">
                      <div className="w-full flex flex-row justify-center">
                        <span className="mr-2pxr overflow-hidden text-ellipsis whitespace-nowrap inline-block align-middle">{t("common.birth_date_aged_day")}</span>
                        <button onClick={() => handleHeaderClick("birth_date", "date")} type="button" className="min-w-[16px]">
                          {getSortIcon(sortStates.find((o) => o.key === "birth_date")?.direction)}
                        </button>
                      </div>
                    </th>
                    <th className="w-123pxr max-w-[123px]">{t("manage.herd_start_date")}</th>
                    <th className="w-123pxr max-w-[123px]">{t("manage.herd_end_date")}</th>
                    <th className="w-123pxr max-w-[123px]">{t("common.heads")}</th>
                    <th className="w-179pxr max-w-[179px]">{t("common.weight")}</th>
                    <th className="w-153pxr max-w-[153px]">{t("common.breeding_status")}</th>
                  </tr>
                  {/* How to set with Sort Icon  */}
                  {/* <tr>
                  <th className="w-159pxr max-w-[159px]">
                    <div className="w-full flex flex-row justify-center">
                      <span className="mr-2pxr overflow-hidden text-ellipsis whitespace-nowrap inline-block align-middle">돈군이름</span>
                      <Sort className="min-w-[16px] inline-block align-middle cursor-pointer" />
                    </div>
                  </th>
                </tr>  */}
                </thead>
                <tbody>
                  {sortedData?.slice(currentPage * PAGELIMIT - PAGELIMIT, currentPage * PAGELIMIT).map((o) => (
                    <tr
                      key={o.id}
                      className="cursor-pointer hover:!bg-ef-primary-50"
                      onClick={() => {
                        setGroupId(o.id);
                        setDetailToggle(true);
                      }}
                    >
                      <td className="max-w-[360px]">{o.nickname ? o.nickname : o.name}</td>
                      <td className="max-w-[220px]">{o.room ? `[${o?.room?.piggery?.name}] ${o.room.name}` : "-"}</td>
                      <td className={"max-w-[159px] " + (o.birth_date === null ? "text-ef-state-red-500" : "")}>
                        {o.birth_date}
                        <span className="text-ef-neutral-900">{o.age ? `(${o.age}${t("common.aged")})` : ""}</span>
                      </td>
                      <td className="max-w-[123px]">{format(new Date(o.arrival_date), "yyyy-MM-dd")}</td>
                      <td className="max-w-[123px]">{o.deactivated_at ? format(new Date(o.deactivated_at), "yyyy-MM-dd") : "-"}</td>
                      <td
                        className="max-w-[123px]"
                        onMouseEnter={() => {
                          if (!o.avg_weight) {
                            setHoveredStock(o.id);
                          }
                        }}
                        onMouseLeave={() => {
                          if (!o.avg_weight) {
                            setHoveredStock(0);
                          }
                        }}
                      >
                        {`${o?.room?.stock ? o?.room?.stock?.toLocaleString() + t("common.head") : `0${t("common.head")}`}`}
                        {!o?.room?.stock && hoveredStock === o.id && o.active_state === true ? (
                          <div className="absolute z-[999] flex items-center justify-center ef-body-sm px-16pxr py-12pxr w-222pxr h-44pxr border rounded-[8px] bg-ef-neutral-white border-ef-neutral-150">
                            {t("manage.no_number_of_moves_in_records")}
                          </div>
                        ) : o?.room?.stock && hoveredStock === o.id ? (
                          <div className="absolute z-[999] flex gap-8pxr flex-row items-center justify-center  px-16pxr py-12pxr w-198pxr h-44pxr border rounded-[8px] bg-ef-neutral-white border-ef-neutral-150">
                            <span className="ef-body-sm">{t("common.stock_updated")}</span>
                            <span className="w-1pxr h-12pxr bg-ef-neutral-200"></span>
                            <span className="ef-body-sm">{o.stock_last_updated_at ? format(new Date(o.stock_last_updated_at), "yyyy-MM-dd") : ""}</span>
                          </div>
                        ) : (
                          ""
                        )}
                      </td>
                      <td
                        onMouseEnter={() => {
                          if (!o.avg_weight) {
                            setHoveredWeight(o.id);
                          }
                        }}
                        onMouseLeave={() => {
                          if (!o.avg_weight) {
                            setHoveredWeight(0);
                          }
                        }}
                        className={"max-w-[179px] " + (o.avg_weight === null ? "text-ef-neutral-500" : "")}
                      >
                        {o.avg_weight ? `${o.avg_weight.toFixed(2)}kg (${t("manage.total")} ${Math.floor(o.avg_weight * o.stock)?.toLocaleString()}kg)` : "-"}

                        {!o.avg_weight && hoveredWeight === o.id ? (
                          <div className="absolute z-[999] flex items-center justify-center ef-body-sm px-16pxr py-12pxr w-222pxr h-44pxr border rounded-[8px] bg-ef-neutral-white border-ef-neutral-150">
                            {t("manage.no_weight_in_move_records")}
                          </div>
                        ) : (
                          ""
                        )}
                      </td>
                      {/* <td className={"max-w-[123px] " + (o.gender === null ? "text-ef-neutral-500" : "")}>{o.gender ? t(`common.gender_${o.gender}`) : t("common.not_selected")}</td>
                      <td className={"max-w-[123px] " + (o.breed === null ? "text-ef-neutral-500" : "")}>{o.breed ? o.breed_info.name : t("manage.not_selected")}</td> */}
                      <td className={"max-w-[153px] ef-body-xs " + (o.active_state === true ? "text-ef-primary-500" : "")}>{o.active_state === true ? t("common.breeding") : t("common.closed")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="mx-auto">{groupList && <Pagination dataLength={groupList.length} pageLimit={PAGELIMIT} currentPage={currentPage} setCurrentPage={setCurrentPage} />}</div>
            </div>
          ) : (sortedData && sortedData.length === 0) || isError ? (
            <div className="flex flex-col items-center justify-center my-120pxr">
              <img className="w-310pxr h-220pxr" src={noData} alt="noData" />
              <div className="ef-body-xxl text-ef-neutral-600 mx-auto">{t("manage.no_group_comment")}</div>
            </div>
          ) : (
            <div className="w-full h-[1120px]"></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ManageGroup;
